@import "~smu-global-styles/globals";

.questionnaire-results-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  &__option {
    margin: 0 25px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__export {
    margin: 0 0 0 auto;
    width: 140px;

    & > *:first-child {
      width: calc(100% - 38px);
    }
  }

  @include screenMobile {
    &.questionnaire-results-header--mobile {
      .questionnaire-results-header {
        &__option {
          margin: 0 0 20px 0;
          width: 33.33%;
        }

        &__export {
          width: 100%;
        }
      }
    }
  }
}
