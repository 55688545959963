@import '~smu-global-styles-suite/reset';
@import '~react-image-gallery/styles/scss/image-gallery';

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

html, body {
  font-size: 16px;
}

#root {
  height: 100%;
  width: 100%;

  & > :first-child {
    height: 100%;
    width: 100%;
  }
}
