@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap);
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

body {
  background: #F0F2F5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-ms-clear,
::-ms-reveal {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*
* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

::-ms-clear,
::-ms-reveal {
    display: none;
}


body {
    font-family: Arial;
    font-size: $typography-style-13;
    margin: 0;
    padding: 0;
}

button {
    font-size: 13.33px;
}

a {
    color: $primary-blue;
    text-decoration: underline;

    &:focus {
        @include tab-focus();
    }

    &:focus,
    &:hover {
        color: $primary-dark-blue;
    }
}

input,
select {
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding-left: 3px;
}

h1 {
    //font: bold $font-jumbo;
}

h2 {
    //font: $font-extra-large;
}

h3 {
    //font: $font-over-large;
}

h4 {
    //font: $font-semi-large;
}

h5 {
    //font: bold $font-small;
}

h6 {
    //font: bold $font-extra-small;
}

a,
button,
h1,s
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
ul {
    font-family: inherit;
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

img {
    border: 0;
}
*/
.image-gallery-icon {
  color: #fff;
  transition: all 0.3s ease-out;
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
.image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
.image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  object-fit: contain;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus {
  transform: scale(1.2);
  background: #337ab7;
  border: 1px solid #337ab7;
}
.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

html, body {
  font-size: 16px;
}

#root {
  height: 100%;
  width: 100%;
}
#root > :first-child {
  height: 100%;
  width: 100%;
}
.app-maintenance .maintenance__product {
  width: 100%;
}
.fullscreen-modal-post {
  animation-duration: 0.5s;
  animation-name: slide-from-top-to-bottom;
  animation-timing-function: ease-in-out;
  height: 100%;
  width: 100%;
}

@keyframes slide-from-top-to-bottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.menu-popup {
  font-family: "Open Sans", sans-serif;
  position: relative;
  z-index: 1;
}
.menu-popup__arrow {
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(24, 24, 26, 0.25);
  height: 14px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  width: 14px;
  z-index: 1;
}
.menu-popup__options {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(24, 24, 26, 0.25);
  display: none;
  list-style: none;
  margin: 0;
  min-width: 200px;
  padding: 6px;
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 0;
}
.menu-popup__options::after {
  background-color: #fff;
  border-radius: 3px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.menu-popup__option {
  align-items: center;
  border-radius: 3px;
  color: #18181a;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  min-height: 34px;
  padding: 4px 10px;
  position: relative;
  transition: all 0.4s ease;
  z-index: 4;
}
.menu-popup__option:hover {
  background-color: rgb(236, 237, 255);
}
.menu-popup__option-icon--left {
  margin-right: 10px;
  font-size: 16px;
}
.menu-popup--open .menu-popup__options {
  display: block;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.post-menu {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 20;
}
.post-menu__icon {
  position: relative;
  top: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.rich-input-text__suggestions {
  margin-top: 10px;
  position: absolute;
  min-width: 220px;
  max-width: 230px;
  background: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 1202;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
}
.rich-input-text__mention {
  font-weight: bold;
  color: #18181A;
}
.rich-input-text__hashtag {
  font-weight: bold;
  color: #18181A;
}
.rich-input-text__entry--item {
  padding: 10px;
  border-bottom: 1px solid #EAEAEA;
}
.rich-input-text__entry--item:hover {
  background: #FAFAFA;
}
.rich-input-text__entry--focused {
  background: #FAFAFA;
}

.DraftEditor-root {
  position: relative;
  height: 100%;
  cursor: text;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.input {
  align-items: center;
  display: flex;
  width: 100%;
}
.input__body {
  position: relative;
  width: 100%;
}
.input__body-field {
  border: 1px solid #DEE0E3;
  min-height: 30px;
  background: #FAFAFA;
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  padding: 0 62px 0 15px;
  border-radius: 4px;
}
.input__body-counter {
  color: #7C8089;
  position: absolute;
  bottom: 25px;
  right: 10px;
  font-size: 12px;
  opacity: 0;
  transition: 0s opacity;
}
.input__body-counter--visible {
  opacity: 1;
  transition: 0.5s opacity;
}
.input__icons {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  right: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.input__icons-icon {
  align-items: center;
  color: #7C8089;
  display: flex;
  height: 20px;
  justify-content: center;
  overflow: hidden;
  width: 20px;
}
.input__icons-icon > span {
  font-size: 16px;
}
.input__icons-icon > span::before {
  -webkit-text-fill-color: #7C8089;
  -webkit-text-stroke: #7C8089;
  color: #7C8089;
  fill: #7C8089;
  text-stroke: #7C8089;
}
.input__icons-icon-image {
  font-size: 13px !important;
}
.input__icons-icon:not(:last-child) {
  margin-right: 5px;
}
.input__submit-btn {
  align-items: center;
  background-color: #C2C7CC;
  border-radius: 50%;
  cursor: not-allowed;
  display: flex;
  flex: none;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  opacity: 0.4;
  padding: 2px 0 0 5px;
  transition: opacity, background-color 0.5s;
  width: 30px;
}
.input__submit-btn--active {
  background-color: #000000;
  opacity: 1;
  cursor: pointer;
}
.input__upload {
  cursor: pointer;
  height: 20px;
  left: 0;
  opacity: 0;
  outline: none;
  overflow: hidden;
  position: absolute;
  width: 20px;
}
.input__upload::-webkit-file-upload-button {
  cursor: pointer;
}
.input .public-DraftEditor-content {
  overflow-x: hidden;
}
.input .public-DraftStyleDefault-block {
  align-items: center;
  line-height: 20px;
  padding-top: 5px;
  white-space: pre-wrap;
}
.input .public-DraftEditorPlaceholder-inner {
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
.input .public-DraftEditorPlaceholder-hasFocus {
  line-height: 28px;
}
.input .ui.input {
  height: 0;
}
.input .ui.icon.input > i.icon {
  top: 15px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.skeleton-comment-input {
  align-items: center;
  display: flex;
  padding: 10px 0;
}
.skeleton-comment-input__button {
  flex: none;
  margin-left: 20px;
}
.skeleton-comment {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  width: 100%;
}
.skeleton-comment__header {
  align-items: center;
  display: flex;
}
.skeleton-comment__avatar {
  margin-right: 6px;
}
.skeleton-comment__date {
  margin-left: auto;
}
.skeleton-comment__description {
  margin-left: 14px;
  margin-top: 7px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.skeleton-feed-item {
  position: relative;
}
.skeleton-feed-item__date {
  position: absolute;
  right: 20px;
  top: 20px;
}
.skeleton-feed-item__body {
  display: flex;
  padding: 20px;
}
.skeleton-feed-item__avatar {
  flex: none;
  margin-right: 15px;
}
.skeleton-feed-item__details {
  width: 100%;
}
.skeleton-feed-item__details > *:first-child {
  margin-bottom: 11px;
}
.skeleton-feed-item__description > * {
  margin-bottom: 7px;
}
.skeleton-feed-item__description > *:last-child {
  margin-bottom: 0;
}
.skeleton-feed-item__badge {
  align-items: center;
  display: flex;
  margin-bottom: 11px;
}
.skeleton-feed-item__badge > *:first-child {
  margin-right: 8px;
}
.skeleton-feed-item__footer {
  align-items: center;
  border-top: solid 1px #dee0e3;
  display: flex;
  padding: 10px 20px;
  width: 100%;
}
.skeleton-feed-item__avatars {
  display: flex;
}
.skeleton-feed-item__actions {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.skeleton-feed-item__actions > * {
  margin-left: 18px;
}
.skeleton-feed-item__comments {
  padding: 0 20px;
}
.skeleton-feed-item--show-comments .skeleton-feed-item__footer {
  border-bottom: solid 1px #dee0e3;
}
.smu-avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;
}
.smu-avatar--rounded {
  border-radius: 100%;
}
.smu-avatar--variant-link {
  cursor: pointer;
}
.smu-avatar--variant-link:hover {
  filter: brightness(110%);
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-avatar-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.smu-avatar-info__title {
  color: #18181a;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}
.smu-avatar-info__title:hover {
  color: #18181a;
}
.smu-avatar-info__description {
  color: #7c8089;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
}
.smu-avatar-info--variant-link .smu-avatar-info__title {
  cursor: pointer;
}
.smu-avatar-info--variant-link .smu-avatar-info__title:hover {
  text-decoration: underline;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-avatar-interaction {
  display: inline-flex;
  position: relative;
}
.smu-avatar-interaction__interaction {
  align-items: center;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  right: -13px;
  min-height: 26px;
  min-width: 26px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.smu-avatar-media {
  align-items: center;
  display: flex;
}
.smu-avatar-media__interaction {
  display: inline-flex;
  margin-right: 20px;
}
.skeleton-avatar-media {
  align-items: center;
  display: flex;
}
.skeleton-avatar-media__avatar {
  margin-right: 20px;
}
.skeleton-avatar-media__description {
  margin-top: 7px;
}
.skeleton-avatar-media-list__item {
  margin-bottom: 20px;
}
.skeleton-avatar-media-list__item:last-child {
  margin-bottom: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-modal-interactions__content {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  outline: none;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}
.smu-modal-interactions__header {
  border-bottom: 1px solid #eaeaea;
  padding: 30px 40px 30px 30px;
}
.smu-modal-interactions__title {
  color: #888888;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.smu-modal-interactions__close {
  padding: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.smu-modal-interactions__close-icon {
  font-size: 12px;
}
.smu-modal-interactions__body {
  display: flex;
  flex-direction: column;
}
.smu-modal-interactions__tabs {
  border-bottom: 1px solid #eaeaea;
}
.smu-modal-interactions__tabs-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: auto;
  padding: 17px 30px;
}
.smu-modal-interactions__tab {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 1;
}
.smu-modal-interactions__tab-item {
  align-items: center;
  display: flex;
  justify-content: center;
}
.smu-modal-interactions__tab-image {
  width: 14px;
  margin-right: 3px;
}
.smu-modal-interactions__user {
  margin-bottom: 18px;
}
.smu-modal-interactions__user:last-child {
  margin-bottom: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.congratulation-event {
  padding: 0;
  margin: 0;
}
.congratulation-event__header {
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  display: flex;
  justify-content: flex-end;
  padding: 6px 10px;
}
.congratulation-event__year {
  color: #B4B4BE;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.congratulation-event__body {
  align-items: center;
  display: flex;
  padding: 15px 20px;
}
.congratulation-event__avatar {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(154, 154, 154, 0.6);
  margin-right: 20px;
}
.congratulation-event__details-name {
  color: #18181A;
  display: inline;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
}
.congratulation-event__details-value {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
}
.congratulation-event__details-value-core {
  align-items: center;
  background-color: #FF3FBF;
  border-radius: 100%;
  display: flex;
  height: 34px;
  justify-content: center;
  margin-right: 10px;
  width: 34px;
}
.congratulation-event__details-value-name {
  color: #FF3FBF;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.06;
  margin: 0;
  text-transform: uppercase;
}
.congratulation-event__details-message {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.14;
}
.congratulation-event__footer > *:first-child {
  background-color: #FAFAFA;
}
.congratulation-event__footer .counter__value {
  margin-left: 6px;
  text-decoration: underline;
}
.congratulation-event--birthday {
  border: solid 2px #FF3FBF;
}
.congratulation-event--anniversary {
  border: solid 2px #9A00F0;
}
.congratulation-event--anniversary .congratulation-event__details-value-core {
  background-color: #9A00F0;
  font-size: 18px;
}
.congratulation-event--anniversary .congratulation-event__details-value-name {
  color: #9A00F0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-modal-congratulation {
  padding: 20px;
}
.smu-modal-congratulation__content {
  border-radius: 3px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  max-width: 560px;
  outline: none;
  position: relative;
  width: 100%;
  z-index: 0;
}
.smu-modal-congratulation__content > * {
  margin-bottom: 0;
}
.smu-modal-congratulation__close {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 7px;
  position: absolute;
  right: -40px;
  top: 0;
}
.smu-modal-congratulation__close-icon {
  font-size: 16px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-modal-confirmation {
  padding: 20px;
}
.smu-modal-confirmation__content {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  outline: none;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}
.smu-modal-confirmation__header {
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px 30px 30px;
}
.smu-modal-confirmation__title {
  color: #888888;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.smu-modal-confirmation__close {
  padding: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.smu-modal-confirmation__close-icon {
  font-size: 12px;
}
.smu-modal-confirmation__body {
  color: #18181a;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  padding: 30px;
}
.smu-modal-confirmation__footer {
  align-items: center;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;
}
.smu-modal-confirmation__footer-action {
  margin-right: 20px;
}
.smu-modal-confirmation__footer-action:last-child {
  margin-right: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-modal-taggued-users {
  padding: 20px;
}
.smu-modal-taggued-users__content {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  outline: none;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}
.smu-modal-taggued-users__header {
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px 30px 30px;
}
.smu-modal-taggued-users__title {
  color: #888888;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.smu-modal-taggued-users__description {
  color: #7c8089;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin-left: 15px;
}
.smu-modal-taggued-users__close {
  padding: 3px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.smu-modal-taggued-users__close-icon {
  font-size: 12px;
}
.smu-modal-taggued-users__users {
  display: flex;
  flex-direction: column;
  padding: 17px 30px;
}
.smu-modal-taggued-users__user {
  margin-bottom: 18px;
}
.smu-modal-taggued-users__user:last-child {
  margin-bottom: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.skeleton-media-item__header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 12px 20px;
}
.skeleton-media-item__media {
  padding-top: 56.25%;
  position: relative;
}
.skeleton-media-item__media > *:first-child {
  position: absolute;
  top: 0;
  left: 16px;
  width: calc(100% - 32px);
}
.skeleton-media-item__description {
  padding: 12px 20px;
}
.skeleton-media-item__footer {
  align-items: center;
  background-color: #fafafa;
  border-top: solid 1px #dee0e3;
  display: flex;
  padding: 12px 20px;
}
.skeleton-media-item__username {
  margin-left: 10px;
}
.skeleton-media-item__actions {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.skeleton-media-item__actions > * {
  margin-left: 18px;
}
.skeleton-media-item__comments {
  padding: 0 20px;
}
.skeleton-media-item--show-comments .skeleton-media-item__footer {
  border-bottom: solid 1px #dee0e3;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-card-photo {
  background-color: #EEEEEE;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.smu-card-photo--pointer {
  cursor: pointer;
}
.smu-card-photo--border {
  border: 2px solid;
}
.smu-card-photo__blur {
  filter: blur(12px);
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  transform: scale(1.1);
  width: 100%;
  z-index: 1;
}
.smu-card-photo__img {
  left: 50%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.smu-card-photo__overlay {
  bottom: -3px;
  height: 75px;
  left: -3px;
  position: absolute;
  z-index: 3;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-card-video {
  background-color: #EEEEEE;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.smu-card-video--pointer {
  cursor: pointer;
}
.smu-card-video__blur {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(12px);
  height: 106%;
  left: -3%;
  position: absolute;
  top: -3%;
  width: 106%;
  z-index: 1;
}
.smu-card-video__play {
  cursor: pointer;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.5));
  font-size: 98px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.smu-card-video__player {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.smu-card-video__player-wrapper {
  height: 100%;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.bt-card-gallery {
  position: relative;
}
.bt-card-gallery--link::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.bt-card-gallery--border {
  border: 2px solid;
}
.bt-card-gallery__count {
  background-color: #18181A;
  border-radius: 3px;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  left: 20px;
  padding: 4px 8px;
  position: absolute;
  top: 20px;
  z-index: 4;
}
.bt-card-gallery__nav-right, .bt-card-gallery__nav-left {
  position: absolute;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  z-index: 4;
}
.bt-card-gallery__nav-right:hover, .bt-card-gallery__nav-left:hover {
  opacity: 0.8;
}
.bt-card-gallery__nav-right:focus, .bt-card-gallery__nav-left:focus {
  outline: none;
}
.bt-card-gallery__nav-right > *, .bt-card-gallery__nav-left > * {
  font-size: 42px;
  text-shadow: 0 0 2px #000000;
}
.bt-card-gallery__nav-right--disabled, .bt-card-gallery__nav-left--disabled {
  display: none;
}
.bt-card-gallery__nav-left {
  left: 0;
}
.bt-card-gallery__nav-right {
  right: 0;
}
.bt-card-gallery__bullets {
  align-items: center;
  background-color: #FAFAFA;
  display: flex;
  justify-content: center;
  min-height: 40px;
}
.bt-card-gallery__bullet {
  background-color: #C2C7CC;
  border-radius: 100%;
  border: 0px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  height: 8px;
  margin: 0 3px;
  width: 8px;
}
.bt-card-gallery__bullet--active, .bt-card-gallery__bullet:hover {
  background-color: #fd742b;
}
.bt-card-gallery__overlay {
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -3px;
  height: 95px;
  left: -3px;
  position: absolute;
  width: 95px;
  z-index: 3;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-modal-star-details {
  padding: 20px;
}
.smu-modal-star-details__content {
  border-radius: 3px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  max-width: 560px;
  outline: none;
  position: relative;
  width: 100%;
  z-index: 0;
}
.smu-modal-star-details__close {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 7px;
  position: absolute;
  right: -40px;
  top: 0;
}
.smu-modal-star-details__close-icon {
  font-size: 16px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.title {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
.title-widget {
  font-size: 14px;
  font-weight: bold;
}
.title-page {
  color: black;
  font-size: 22px;
  font-weight: bolder;
}
.title-page-section {
  font-size: 16px;
  font-weight: bolder;
  padding-bottom: 12px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-generic-modal {
  display: flex;
  flex-direction: column;
  max-height: 98vh;
  position: relative;
}
.smu-generic-modal-content {
  border-radius: 5px;
  overflow: hidden;
}
.smu-generic-modal__header {
  border-bottom: solid 1px rgba(194, 199, 204, 0.3);
  padding: 30px;
}
.smu-generic-modal__title {
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
}
.smu-generic-modal__close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}
.smu-generic-modal__close::before {
  -webkit-text-fill-color: #7C8089;
  -webkit-text-stroke: #7C8089;
  color: #7C8089;
  fill: #7C8089;
  text-stroke: #7C8089;
}
.smu-generic-modal__close:hover {
  opacity: 0.5;
}
.smu-generic-modal__body {
  color: #7C8089;
  flex: 1 1;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.19;
  padding: 30px;
  overflow: auto;
}
.smu-generic-modal__footer {
  border-top: solid 1px rgba(194, 199, 204, 0.3);
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.smu-generic-modal__action {
  margin-left: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.button--media_google-plus, .button--media_google-plus:hover, .button--media_google-plus:focus {
  background-color: #D14836 !important;
}
.button--media_facebook-workplace, .button--media_facebook-workplace:hover, .button--media_facebook-workplace:focus {
  background-color: #373F4C !important;
}
.button--media_linkedin, .button--media_linkedin:hover, .button--media_linkedin:focus {
  background-color: #007BB5 !important;
}
.button--media_twitter, .button--media_twitter:hover, .button--media_twitter:focus {
  background-color: #00ACED !important;
}
.button--media_facebook, .button--media_facebook:hover, .button--media_facebook:focus {
  background-color: #3B5998 !important;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.share-container {
  overflow: hidden;
  position: relative;
}
.share-container__preview {
  position: relative;
  width: 100%;
  z-index: 6;
}
.share-container__preview--loading {
  display: none;
}
.share-container__html {
  opacity: 0;
  position: absolute;
  visibility: hidden;
}
.share-portal {
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.static-DNA {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.static-DNA__level {
  align-items: center;
  display: flex;
  height: 11px;
  margin-bottom: 3px;
  position: relative;
  width: 100%;
}
.static-DNA__level:last-child {
  margin-bottom: 0px;
}
.static-DNA__level-circle {
  border-radius: 100%;
  flex: none;
  position: absolute;
  width: 10px;
}
.static-DNA__level-circle:last-child {
  left: auto;
  right: 0;
}
.static-DNA__level-circle::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.static-DNA__level-line {
  background-color: #dee0e3;
  height: 1px;
  width: 100%;
}
.static-DNA__level:nth-child(10n+1) {
  width: 87px;
}
.static-DNA__level:nth-child(10n+1) .static-DNA__level-circle {
  width: 9px;
}
.static-DNA__level:nth-child(10n+1) .static-DNA__level-circle:last-child {
  width: 13px;
}
.static-DNA__level:nth-child(10n+2) {
  width: 84px;
}
.static-DNA__level:nth-child(10n+2) .static-DNA__level-circle {
  width: 10px;
}
.static-DNA__level:nth-child(10n+2) .static-DNA__level-circle:last-child {
  width: 12px;
}
.static-DNA__level:nth-child(10n+3) {
  width: 75px;
}
.static-DNA__level:nth-child(10n+3) .static-DNA__level-circle {
  width: 11px;
}
.static-DNA__level:nth-child(10n+3) .static-DNA__level-circle:last-child {
  width: 11px;
}
.static-DNA__level:nth-child(10n+4) {
  width: 61px;
}
.static-DNA__level:nth-child(10n+4) .static-DNA__level-circle {
  width: 12px;
}
.static-DNA__level:nth-child(10n+4) .static-DNA__level-circle:last-child {
  width: 10px;
}
.static-DNA__level:nth-child(10n+5) {
  width: 38px;
}
.static-DNA__level:nth-child(10n+5) .static-DNA__level-circle {
  width: 14px;
}
.static-DNA__level:nth-child(10n+5) .static-DNA__level-circle:last-child {
  width: 8px;
}
.static-DNA__level:nth-child(10n+6) {
  width: 14px;
}
.static-DNA__level:nth-child(10n+6) .static-DNA__level-circle {
  width: 13px;
}
.static-DNA__level:nth-child(10n+6) .static-DNA__level-circle:last-child {
  width: 0;
}
.static-DNA__level:nth-child(10n+7) {
  width: 34px;
}
.static-DNA__level:nth-child(10n+7) .static-DNA__level-circle {
  width: 8px;
}
.static-DNA__level:nth-child(10n+7) .static-DNA__level-circle:last-child {
  width: 13px;
}
.static-DNA__level:nth-child(10n+8) {
  width: 56px;
}
.static-DNA__level:nth-child(10n+8) .static-DNA__level-circle {
  width: 8px;
}
.static-DNA__level:nth-child(10n+8) .static-DNA__level-circle:last-child {
  width: 14px;
}
.static-DNA__level:nth-child(10n+9) {
  width: 73px;
}
.static-DNA__level:nth-child(10n+9) .static-DNA__level-circle {
  width: 8px;
}
.static-DNA__level:nth-child(10n+9) .static-DNA__level-circle:last-child {
  width: 14px;
}
.static-DNA__level:nth-child(10n+10) {
  width: 83px;
}
.static-DNA__level:nth-child(10n+10) .static-DNA__level-circle {
  width: 9px;
}
.static-DNA__level:nth-child(10n+10) .static-DNA__level-circle:last-child {
  width: 13px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.share-DNA {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 140px;
  position: relative;
  width: 1200px;
}
.share-DNA__header {
  background-color: #db30fa;
  background-image: linear-gradient(to left, #db30fa 25%, #14145a 106%);
  border: solid 1px rgba(0, 0, 0, 0);
  height: 6px;
  margin: 0 0 2px;
  width: 100%;
}
.share-DNA__avatar {
  border-radius: 100%;
  height: 160px;
  width: 160px;
}
.share-DNA__info-user {
  flex: none;
  overflow: hidden;
  padding: 0 55px 0;
  position: relative;
  width: 35%;
}
.share-DNA__info-user > * {
  position: relative;
  z-index: 1;
}
.share-DNA__title {
  color: #14145a;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.21;
  margin: 0;
}
.share-DNA__description {
  color: #14145a;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  line-height: 1.23;
  margin-top: 20px;
}
.share-DNA__user {
  align-items: center;
  display: flex;
  margin-top: 53px;
  flex-direction: column;
}
.share-DNA__user-wrapper {
  border-radius: 180px;
  height: 180px;
  margin-bottom: 20px;
  position: relative;
  width: 180px;
}
.share-DNA__user-wrapper > *:first-child {
  border: 10px solid #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.share-DNA__user-description {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.share-DNA__user-name {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 20px;
}
.share-DNA__user-job {
  color: #7c8089;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 1.38;
  margin-bottom: 40px;
}
.share-DNA__user-labels {
  margin-top: 7px;
}
.share-DNA__user-label {
  background-color: rgba(255, 63, 191, 0.05);
  border-radius: 10px;
  color: #ff3fbf;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.32;
  padding: 5px 40px 5px 40px;
  text-transform: inherit;
}
.share-DNA__organization-logo {
  max-height: 160px;
  max-width: 160px;
}
.share-DNA__metrics {
  display: flex;
  margin-top: 60px;
}
.share-DNA__metric {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}
.share-DNA__metric-icon {
  align-items: center;
  background-color: #c823eb;
  border-radius: 100%;
  color: #fff;
  display: flex;
  flex: none;
  font-size: 28px;
  height: 46px;
  justify-content: center;
  margin-right: 15px;
  width: 46px;
}
.share-DNA__metric-total {
  color: #14145a;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.23;
}
.share-DNA__metric-text {
  color: #14145a;
  flex: none;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  line-height: 1.23;
  margin-top: 9px;
  width: 100%;
}
.share-DNA__stats {
  align-items: center;
  display: flex;
  padding: 47px 15px 0;
  width: 65%;
  position: relative;
  z-index: 3;
}
.share-DNA__dna-text {
  align-content: space-between;
  display: flex;
  flex-direction: column;
  height: 80%;
  padding-left: 60px;
}
.share-DNA__list {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  list-style: none;
  padding: 0;
}
.share-DNA__list-item {
  align-items: center;
  display: flex;
  height: 100%;
  max-height: 68px;
}
.share-DNA__list-item--disabled .share-DNA__list-value, .share-DNA__list-item--disabled .share-DNA__list-text {
  color: #c2c7cc;
}
.share-DNA__list-circle {
  border-radius: 50%;
  display: inline-flex;
  height: 14px;
  margin-right: 15px;
  width: 14px;
}
.share-DNA__list-value, .share-DNA__list-text {
  color: #18181a;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: bold;
}
.share-DNA__list-value {
  margin-right: 15px;
}
.share-DNA__logo {
  bottom: 50px;
  height: 60px;
  position: absolute;
  right: 20px;
  width: 150px;
  z-index: 4;
}
.share-DNA__shapes {
  bottom: -25px;
  position: absolute;
  right: 10px;
  z-index: 2;
}
.share-DNA__footer {
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.share-modal__footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 30px;
}
.share-modal__footer-title {
  color: #7c8089;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.31;
  margin-bottom: 15px;
}
.share-modal__footer-socials {
  display: flex;
}
.share-modal__body {
  overflow-y: auto;
  padding: 30px 30px;
}
.share-modal__social-btn {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  margin-right: 10%;
}
.share-modal__social-name {
  color: #18181a;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.31;
  margin-top: 5px;
  text-transform: capitalize;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.recognition-event__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.recognition-event__content {
  margin-right: 10px;
  width: calc(70% - 10px);
}
@media only screen and (max-width: 1091px) {
  .recognition-event__content {
    width: 100%;
  }
}
.recognition-event__content .event-header__title {
  text-transform: uppercase;
}
.recognition-event__button {
  margin: 0;
  padding: 0 10px;
}
.recognition-event__button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}
@media only screen and (max-width: 1091px) {
  .recognition-event__button-wrapper {
    justify-content: flex-start;
    margin-top: 12px;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.event-card {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.event-card__container {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: solid 1px #DEE0E3;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.event-header {
  display: flex;
  flex-direction: column;
}
.event-header__title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.29;
  margin: 0;
  text-align: left;
}
.event-header__subtitle {
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.29;
  margin: 5px 0 0 0;
  text-align: left;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.tooltip-user {
  padding: 8px 20px !important;
  text-align: center;
}
.tooltip-user__text {
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  max-width: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tooltip-user__name {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 0;
  max-width: 100%;
  overflow: inherit;
}
.tooltip-user__job {
  color: #7C8089;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  margin-bottom: 0;
  margin-top: 2px;
  max-width: 100%;
  overflow: inherit;
}

.custom-border {
  border: 3px solid;
}
.custom-border--rocking_star {
  border-color: #ff3fbf;
}
.custom-border--shining_star {
  border-color: #cf21d6;
}
.custom-border--rising_star {
  border-color: #9a00f0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.recognition-content {
  display: flex;
  margin-top: 12px;
}
.recognition-content__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 20px;
  position: relative;
  width: 50%;
}
.recognition-content__container-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  margin-top: 0;
  overflow: hidden;
  width: 100%;
}
.recognition-content__text {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.14;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.recognition-content__text--bold {
  font-weight: bold;
  line-height: 1.14;
  color: #18181A;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.content-type {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.content-type__container {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: solid 1px #DEE0E3;
  width: 100%;
  padding: 20px;
}
.content-type__header {
  display: flex;
  flex-direction: column;
}
.content-type__header-title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.29;
  margin: 0;
  text-align: left;
}
.content-type__header-subtitle {
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.29;
  margin: 5px 0 0 0;
  text-align: left;
}
.content-type__content {
  align-items: center;
  display: flex;
  margin-top: 15px;
  margin-right: 30px;
}
.content-type__content--expanded {
  margin-right: 0;
}
.content-type__content-avatars {
  display: flex;
  margin-left: 40px;
}
.content-type__carousel {
  align-items: center;
  display: flex;
  height: 104px;
  justify-content: center;
  margin-left: 35px;
  padding: 0 20px 0 10px;
  width: 100%;
}
.content-type__carousel-container {
  width: 100%;
}
.content-type__carousel--expanded {
  margin-left: 0;
  padding: 0 0 0 10px;
}
.content-type__avatars {
  display: flex;
  overflow: hidden;
  padding-left: 40px;
  width: 100%;
}
.content-type__avatars-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.content-type__avatars-container--expanded {
  overflow: unset;
}
.content-type__avatars-popup {
  padding: 8px 20px !important;
  text-align: center;
}
.content-type__avatars--expanded {
  overflow: unset;
  width: calc(100% - 80px);
}
.content-type .suite-carousel__item {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.image-event-card {
  margin-bottom: 10px;
}
.image-event__header {
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
}
.image-event__header--bold {
  color: #000000;
  font-weight: bold;
}
.image-event__footer {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  margin: 0;
  text-align: left;
}
.image-event__footer--bold {
  color: #000000;
  font-weight: bold;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.image-event-card {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: solid 1px #DEE0E3;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.image-event-image {
  border-radius: 3px;
  cursor: pointer;
  flex: none;
  object-fit: contain;
  overflow: hidden;
  position: relative;
}
.image-event-image__wrapper {
  padding-top: 100%;
}
.image-event-image__img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}
.image-event-image__img-blur {
  background-size: contain;
  filter: blur(8px);
  height: 110%;
  left: -5%;
  opacity: 0.7;
  position: absolute;
  top: -5%;
  width: 110%;
  z-index: 1;
}
.image-event-image:last-child {
  margin-right: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.image-event-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px;
}
.image-event-container__text {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.25;
  margin: 0;
  text-align: left;
  text-transform: uppercase;
}
.image-event-container__item {
  margin-right: 10px;
  width: calc(20% - 8px);
}
.image-event-container__last-item {
  align-items: center;
  background-image: linear-gradient(41deg, #fc3d2c 4%, #fc462c 16%, #fd5e2c 37%, #fe862b 63%, #ffbc2a 92%, #ffc42a 96%);
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  padding: 0 10px;
  width: calc(20% - 8px);
}
.image-event-container__loader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 105px;
  width: 100%;
}
.image-event-container .mentions-parser__item--user, .image-event-container .mentions-parser__item--hashtag {
  font-weight: bold;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.image-event-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
.image-event-header__timestamp {
  color: #C2C7CC;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.star {
  position: inherit;
  margin: 0;
}
.star__content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 134px;
  padding: 20px 10px 20px 20px;
  position: relative;
}
.star__section-top {
  display: flex;
}
.star__section-top-header {
  width: 100%;
}
.star__avatar-wrapper {
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  flex: none;
  height: 108px;
  margin: 0 15px 0 0;
  min-width: 90px;
  overflow: hidden;
  width: 90px;
}
.star__avatar {
  margin-right: 15px;
}
.star__description-wrapper {
  width: 100%;
}
.star__description-notes {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  overflow: hidden;
  white-space: pre-line;
  word-wrap: break-word;
}
.star__description-notes--shaddy {
  opacity: 0.4;
}
.star__avatar-title {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 0 0 11px 0;
}
.star__header {
  align-items: flex-start;
  display: flex;
  width: 100%;
}
.star__header--empty-user {
  height: 0;
}
.star__date-menu {
  display: flex;
  flex: none;
  margin-left: auto;
}
.star__post-menu {
  margin-left: 10px;
  position: relative;
  top: -15px;
}
.star__timestamp {
  color: #C2C7CC;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: -10px;
}
.star__avatar-name {
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-right: 15px;
}
.star__avatar-name:hover {
  color: #18181A;
  text-decoration: underline;
}
.star__badge-wrapper {
  margin: 0 0 11px 0;
}
.star__badge-image-wrapper {
  display: inline-block;
  border-radius: 50%;
}
.star__badge-image {
  vertical-align: middle;
}
.star__badge-description {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin: 0 0 0 8px;
  max-width: 150px;
  text-transform: uppercase;
  vertical-align: middle;
}
.star__avatar-list {
  padding-top: 20px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.post-comment {
  margin: 30px 20px 17px 0px;
  position: relative;
}
.post-comment__title {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.post-comment__textarea {
  border: 1px solid #DEE0E3;
  height: 100%;
  margin-top: 10px;
  position: relative;
}
.post-comment__addons {
  align-items: center;
  display: flex;
  height: 40px;
  position: relative;
  width: 100%;
}
.post-comment__rich-input-text {
  border-radius: 3px;
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 20px;
}
.post-comment__rich-input-text--counter {
  color: #C2C7CC;
  font-family: "Roboto", sans-serif;
  position: absolute;
  right: 20px;
}
.post-comment__emoji {
  background-color: transparent;
  border: none;
  left: 20px;
  padding: 0;
  position: absolute;
}
.post-comment__emoji-popup {
  position: absolute;
  z-index: 900;
}
.post-comment__error {
  color: #D5152E;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  margin-top: 10px;
  min-height: 32px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.bt-modal-confirmation {
  padding-top: 50px;
}
.bt-modal-confirmation__panel {
  padding: 30px;
}
.bt-modal-confirmation__close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}
.bt-modal-confirmation__close > * {
  font-size: 15px;
}
.bt-modal-confirmation__close > *::before {
  -webkit-text-fill-color: #000000;
  -webkit-text-stroke: #000000;
  color: #000000;
  fill: #000000;
  text-stroke: #000000;
}
.bt-modal-confirmation__body {
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  padding: 30px 0px;
}
.bt-modal-confirmation__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}
.bt-modal-confirmation__footer > * {
  margin: 0 0 0 10px;
}
.smu-html {
  color: #212121;
  font-size: 14px;
}
.smu-html h1, .smu-html h2, .smu-html h3, .smu-html h4, .smu-html h5, .smu-html h6 {
  display: block;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}
.smu-html h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
.smu-html h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
.smu-html h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.smu-html h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
.smu-html h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
.smu-html h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}
.smu-html a {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}
.smu-html ol {
  display: block;
  list-style-type: decimal;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.smu-html ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.smu-html li {
  display: list-item;
}
.smu-html p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}
.smu-html br {
  content: "";
  display: block;
  margin-top: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.agreement {
  width: 100%;
  border-color: #FFFFFF;
}
.agreement__header {
  height: 82px;
  background-color: #FAFAFA;
  border-bottom: 2px solid #EAEAEA;
  padding: 33px 0 30px 30px;
  color: #B4B4BE;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: bold;
}
.agreement__body {
  background-color: #FFFFFF;
  border-bottom: 2px solid #EAEAEA;
  overflow: auto;
  padding: 25px 20px;
  color: #7C8089;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
  max-height: 300px;
}
.agreement__body--spinner {
  text-align: center;
}
.agreement__footer {
  height: 127px;
  background-color: #FFFFFF;
}
.agreement__footer--agreement {
  padding: 20px 0px 23px 20px;
  display: block;
}
.agreement__footer--text {
  color: #7C8089;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  padding-left: 7px;
  vertical-align: super;
  line-height: 19px;
}
.agreement__footer--buttons {
  display: flex;
  padding: 0 20px 20px 0;
  text-align: right;
}
.agreement__footer--buttons button {
  margin: 10px;
}
.agreement__footer--checkbox {
  float: left;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-experience {
  margin-bottom: 0;
}
.send-experience__message {
  padding: 40px 0 0 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.send-experience__input {
  width: 100%;
  margin: 10px 0 0 0;
  height: 50px !important;
}
.send-experience__textarea {
  width: 100%;
  outline: none;
  height: 173px;
  margin: 10px 0 0 0;
  padding: 25px;
}
.send-experience__buttons {
  margin: 10px auto 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
}
.send-experience > .send-experience__input {
  padding: 0;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insight-details {
  margin: 0 auto;
  max-width: 1100px;
  min-height: 350px;
  width: 100%;
}
.insight-details__back-to-and-period {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.insight-details__back-to {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: bold;
}
.insight-details__back-to:focus, .insight-details__back-to:hover {
  color: #18181A;
}
.insight-details__back-to--icon {
  padding-right: 5px;
}
.insight-details__back-to--grey {
  color: #7C8089;
  cursor: pointer;
}
.insight-details__period {
  color: #14145a;
  font-size: 14px;
}
.insight-details__panel {
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(24, 24, 26, 0.1);
  display: flex;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
  justify-content: space-between;
  margin: 0;
  padding: 20px 30px;
}
.insight-details__panel--is-modal {
  border: none;
  box-shadow: none;
  padding: 0 0 20px 0;
}
.insight-details__title {
  color: #412296;
  font-family: "Rubik", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.23;
  width: 100%;
}
.insight-details__description {
  color: #18181A;
  font-size: 20px;
  line-height: 1.45;
  margin: 20px 0;
  width: 100%;
}
.insight-details__content {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}
.insight-details__tip {
  color: #7C8089;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-top: 10px;
}
.insight-details__tip-intro {
  margin: 0;
}
.insight-details__tip-option {
  cursor: pointer;
  margin: 0 0 0 7px;
  text-decoration: underline;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insight-details__content {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .insight-details__tip {
    justify-content: center;
  }
  .insight-details__content {
    align-items: flex-start;
    flex-direction: column;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content-actions {
  color: #412296;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  justify-content: center;
  width: 30%;
}
.insights-content-actions__title {
  font-size: 24px;
  margin: 20px 0;
  width: 100%;
}
.insights-content-actions__item {
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #412296;
  color: #412296;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10px;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
.insights-content-actions__item:hover {
  border: 2px solid #7049dd;
  color: #7049dd;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content-actions {
    margin-top: 20px;
    width: 100%;
  }
  .insights-content-actions__title {
    margin: 10px 20px;
  }
}
@media (max-width: 767px) {
  .insights-content-actions {
    margin-top: 20px;
    width: 100%;
  }
  .insights-content-actions__title {
    margin: 10px 20px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-leaderboard {
  color: #412296;
  font-family: "Rubik", sans-serif;
  justify-content: center;
  width: 30%;
}
.insights-leaderboard__title {
  color: #18181A;
  font-size: 14px;
  line-height: 1.43;
}
.insights-leaderboard__view-all {
  cursor: pointer;
  color: #412296;
  font-family: "Rubik", sans-serif;
  text-align: center;
}
.insights-leaderboard__list-item {
  align-items: center;
  color: #18181A;
  display: flex;
  font-size: 24px;
  line-height: 1.04;
  padding: 6px 0 0 9px;
  text-align: left;
}
.insights-leaderboard__list-item--main-user {
  background-color: #efe8f2;
  border-radius: 15px;
  color: #412296;
  padding: 6px 0 0 9px;
}
.insights-leaderboard__list-item--main-user .avatar-media__body .heading {
  color: #412296;
}
.insights-leaderboard__list-item--position {
  font-weight: bold;
  height: 32px;
  margin: 5px 10px 5px 0;
}
.insights-leaderboard__list-item--user {
  margin: 5px 10px 5px 0;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-leaderboard {
    margin: 0 auto;
    width: 80%;
  }
}
@media (max-width: 767px) {
  .insights-leaderboard {
    margin: 0 auto;
    width: 80%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.my-team-modal {
  height: auto;
}
.my-team-modal__button {
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.my-team-modal__content {
  display: flex;
  flex-wrap: wrap;
  height: 545px;
  padding: 10px 15px;
  overflow-y: scroll;
}
.my-team-modal__header {
  align-items: center;
  background-color: #FAFAFA;
  border-bottom: 1px solid rgba(180, 180, 190, 0.3);
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 30px;
  color: #c2c7cc;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.my-team-modal__list {
  flex-wrap: wrap;
  display: flex;
  margin: 20px;
}
.my-team-modal__list-item {
  color: #18181A;
  font-size: 24px;
  line-height: 1.04;
  padding: 6px 0 0 10px;
  width: 300px;
}
.my-team-modal__list-item--user {
  margin: 5px 10px 5px 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content__cultural {
  align-items: flex-start;
  color: #412296;
  font-family: "Rubik", sans-serif;
  justify-content: space-around;
  width: 35%;
}
.insights-content__cultural-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
.insights-content__cultural-description {
  color: #412296;
  font-size: 24px;
  line-height: 1.23;
  margin-top: 10px;
}
.insights-content__cultural-indicator {
  display: flex;
  color: #18181A;
  font-size: 12px;
  line-height: 1.43;
  margin-top: 10px;
  align-items: center;
}
.insights-content__cultural-indicator--arrow {
  margin-right: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content__cultural {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .insights-content__cultural {
    margin-bottom: 20px;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content__graph {
  margin: 0 20px;
  width: 40%;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content__graph {
    margin: 0 20%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .insights-content__graph {
    margin: 0;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content__graph {
  margin: 0 20px;
  width: 40%;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content__graph {
    margin: 0 20%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .insights-content__graph {
    margin: 0;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content__kpi {
  align-items: flex-start;
  color: #412296;
  font-family: "Rubik", sans-serif;
  justify-content: space-around;
  width: 35%;
}
.insights-content__kpi-value {
  font-size: 50px;
  font-weight: bold;
}
.insights-content__kpi-description {
  font-size: 26px;
  line-height: 1.23;
  margin-top: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content__kpi {
    margin-bottom: 20px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .insights-content__kpi {
    margin-bottom: 20px;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content__line-graph {
  margin: 0 20px;
  width: 40%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content__line-graph {
    margin: 0 auto;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .insights-content__line-graph {
    margin: 0 auto;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content-organization-value {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 30%;
}
.insights-content-organization-value__image {
  margin-bottom: 20px;
  width: 110px;
}
.insights-content-organization-value__name {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  max-width: 60%;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content-organization-value {
    margin-bottom: 30px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .insights-content-organization-value {
    margin-bottom: 30px;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content-user-grid {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights-content-user-grid {
    margin: 0 auto;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .insights-content-user-grid {
    flex-direction: column;
    width: 100% !important;
  }
  .insights-content-user-grid-column {
    width: 100% !important;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content-miss-somebody {
  color: #18181A;
  display: block;
  font-family: "Rubik", sans-serif;
  font-size: 11px;
  margin-top: 5px;
  text-align: center;
  text-decoration: underline;
}
.insights-content-miss-somebody:hover {
  color: #18181A;
  text-decoration: underline;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content-user {
  align-items: center;
  border-radius: 15px;
  display: flex;
  padding: 9px;
  position: relative;
  width: 100%;
}
.insights-content-user:hover {
  background-color: #efe8f2;
}
.insights-content-user:hover .insights-content-user__details-name {
  color: #412296;
}
.insights-content-user:hover .insights-content-user__arrow {
  display: block;
}
.insights-content-user__image {
  border-radius: 100%;
  border: 1px solid #FFFFFF;
  height: 40px;
  margin-right: 10px;
  width: 40px;
}
.insights-content-user__details {
  flex-grow: 1;
  max-width: calc(100% - 50px);
}
.insights-content-user__details-name {
  color: #18181A;
  display: block;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 5px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.insights-content-user__details-job {
  color: #18181A;
  display: block;
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  line-height: 1;
}
.insights-content-user__arrow {
  display: none;
  position: absolute;
  right: 5px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-content-view-more {
  cursor: pointer;
  font-style: normal !important;
  font-weight: bold;
  text-decoration: underline;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.group-list {
  width: 100%;
  border-color: #FFFFFF;
}
.group-list__header {
  height: 82px;
  background-color: #FAFAFA;
  border-bottom: 1px solid #EAEAEA;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.group-list__footer {
  border-top: 1px solid #EAEAEA;
  padding: 20px 30px;
  display: flex;
  justify-content: flex-end;
}
.group-list__column {
  float: left;
  width: 50%;
  font-size: 16px;
  color: #B4B4BE;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  padding: 21px 30px 0 30px;
}
.group-list__column--left {
  text-align: left;
}
.group-list__column--right {
  text-align: right;
}
.group-list__column--center {
  text-align: center;
}
.group-list__column--time {
  color: #18181A;
  text-transform: capitalize;
}
.group-list__content {
  height: 310px;
}
.group-list__kudos {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 30px;
  max-height: 310px;
  overflow-y: auto;
}
.group-list__kudos-item {
  width: 48%;
  min-width: 48%;
  display: flex;
  align-items: center;
  margin: 0 auto 25px 0;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .group-list__kudos-item {
    width: 100%;
  }
}
.group-list__kudos-item .avatar-media {
  margin: 0 0 0 15px;
}
.group-list__kudos-item input {
  -webkit-appearance: none;
          appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #DEE0E3;
  padding: 9px;
  border-radius: 3px;
  position: relative;
  outline: none;
  cursor: pointer;
}
.group-list__kudos-item input:checked:after {
  content: "";
  position: absolute;
  background: url(/static/media/tick.c0cbd084.svg) no-repeat;
  background-size: 14px;
  background-position: 50% 50%;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.kudos-expanded-user-list {
  height: 100%;
  overflow: auto;
  padding: 15px;
}
.kudos-expanded-user-list__users {
  display: flex;
  flex-wrap: wrap;
}
.kudos-expanded-user-list__users > * {
  padding: 15px;
  width: 50%;
}
.kudos-expanded-user-list__users > *:hover {
  background-color: rgba(222, 224, 227, 0.2);
  border-radius: 8px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.modals.dimmer .ui.scrolling.modal.living-values-modal__modal {
  position: fixed !important;
}

.living-values-modal {
  background-color: #151758;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  border-radius: 1px !important;
  height: 666px;
  position: relative;
}
.living-values-modal__segment {
  height: 100%;
}
.living-values-modal__segment > *:first-child {
  height: 100%;
}
.living-values-modal__segment--loading > *:first-child {
  align-items: center;
  display: flex;
}
.living-values-modal__close-icon {
  font-size: 14px;
}
.living-values-modal__close-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }
  85% {
    opacity: 1;
    transform: scale(2.8);
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
    transform: scale(3.5);
  }
}
.stars {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.stars .container {
  position: absolute;
  animation: stars linear infinite;
}

.stars .container .star {
  animation: twinkle linear infinite;
  border-radius: 100%;
  transform: translateZ(0);
}

.stars .container:nth-child(0) {
  width: 1px;
  height: 1px;
  left: 80.5vw;
  animation-delay: -999.4s;
  animation-duration: 226.8s;
}

.stars .container:nth-child(0) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.3s;
  animation-duration: 32.9s;
  background: rgba(237, 239, 247, 0.8);
}

.stars .container:nth-child(1) {
  width: 3px;
  height: 3px;
  left: 52.5vw;
  animation-delay: -994.5333333333s;
  animation-duration: 34.3333333333s;
}

.stars .container:nth-child(1) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.8s;
  animation-duration: 40.3s;
  background: #c8c5c0;
}

.stars .container:nth-child(2) {
  width: 2px;
  height: 2px;
  left: 44vw;
  animation-delay: -994.4s;
  animation-duration: 119s;
}

.stars .container:nth-child(2) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.9s;
  animation-duration: 12.6s;
  background: #dab4f8;
}

.stars .container:nth-child(3) {
  width: 1px;
  height: 1px;
  left: 84.5vw;
  animation-delay: -993.8s;
  animation-duration: 307.8s;
}

.stars .container:nth-child(3) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47.8s;
  animation-duration: 37.1s;
  background: rgba(237, 225, 178, 0.7);
}

.stars .container:nth-child(4) {
  width: 1px;
  height: 1px;
  left: 69vw;
  animation-delay: -993.8s;
  animation-duration: 47.2s;
}

.stars .container:nth-child(4) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.5s;
  animation-duration: 15.6s;
  background: rgba(207, 201, 219, 0.9);
}

.stars .container:nth-child(5) {
  width: 1px;
  height: 1px;
  left: 16.5vw;
  animation-delay: -981.8s;
  animation-duration: 370.6s;
}

.stars .container:nth-child(5) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.7s;
  animation-duration: 6.2s;
  background: rgba(243, 247, 218, 0.9);
}

.stars .container:nth-child(6) {
  width: 3px;
  height: 3px;
  left: 45.5vw;
  animation-delay: -998.1333333333s;
  animation-duration: 56.6666666667s;
}

.stars .container:nth-child(6) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.2s;
  animation-duration: 12.1s;
  background: #f5eea1;
}

.stars .container:nth-child(7) {
  width: 2px;
  height: 2px;
  left: 99vw;
  animation-delay: -996s;
  animation-duration: 166.3s;
}

.stars .container:nth-child(7) .star {
  width: inherit;
  height: inherit;
  animation-delay: -41.8s;
  animation-duration: 49.9s;
  background: rgba(205, 215, 220, 0.7);
}

.stars .container:nth-child(8) {
  width: 2px;
  height: 2px;
  left: 83vw;
  animation-delay: -993.4s;
  animation-duration: 177.4s;
}

.stars .container:nth-child(8) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.4s;
  animation-duration: 27.5s;
  background: rgba(250, 219, 133, 0.7);
}

.stars .container:nth-child(9) {
  width: 1px;
  height: 1px;
  left: 97vw;
  animation-delay: -983.6s;
  animation-duration: 268.2s;
}

.stars .container:nth-child(9) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.5s;
  animation-duration: 8.7s;
  background: rgba(242, 237, 255, 0.9);
}

.stars .container:nth-child(10) {
  width: 3px;
  height: 3px;
  left: 58vw;
  animation-delay: -996s;
  animation-duration: 28.1333333333s;
}

.stars .container:nth-child(10) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.4s;
  animation-duration: 14.8s;
  background: rgba(226, 229, 204, 0.8);
}

.stars .container:nth-child(11) {
  width: 3px;
  height: 3px;
  left: 43.5vw;
  animation-delay: -998.6s;
  animation-duration: 75.8s;
}

.stars .container:nth-child(11) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.9s;
  animation-duration: 40.2s;
  background: rgba(244, 251, 203, 0.8);
}

.stars .container:nth-child(12) {
  width: 1px;
  height: 1px;
  left: 40vw;
  animation-delay: -995.2s;
  animation-duration: 375.8s;
}

.stars .container:nth-child(12) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.9s;
  animation-duration: 24.9s;
  background: rgba(212, 188, 156, 0.8);
}

.stars .container:nth-child(13) {
  width: 3px;
  height: 3px;
  left: 34.5vw;
  animation-delay: -997.9333333333s;
  animation-duration: 35.5333333333s;
}

.stars .container:nth-child(13) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.2s;
  animation-duration: 45.4s;
  background: rgba(255, 210, 131, 0.8);
}

.stars .container:nth-child(14) {
  width: 2px;
  height: 2px;
  left: 50.5vw;
  animation-delay: -994s;
  animation-duration: 190.8s;
}

.stars .container:nth-child(14) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.5s;
  animation-duration: 43.7s;
  background: #d5e9da;
}

.stars .container:nth-child(15) {
  width: 2px;
  height: 2px;
  left: 19.5vw;
  animation-delay: -990.8s;
  animation-duration: 82s;
}

.stars .container:nth-child(15) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.6s;
  animation-duration: 11.8s;
  background: rgba(239, 242, 164, 0.7);
}

.stars .container:nth-child(16) {
  width: 2px;
  height: 2px;
  left: 6.5vw;
  animation-delay: -999.3s;
  animation-duration: 184.9s;
}

.stars .container:nth-child(16) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.9s;
  animation-duration: 46.3s;
  background: rgba(242, 157, 247, 0.8);
}

.stars .container:nth-child(17) {
  width: 1px;
  height: 1px;
  left: 64.5vw;
  animation-delay: -987.2s;
  animation-duration: 292.4s;
}

.stars .container:nth-child(17) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47s;
  animation-duration: 35.9s;
  background: rgba(204, 173, 124, 0.9);
}

.stars .container:nth-child(18) {
  width: 2px;
  height: 2px;
  left: 10vw;
  animation-delay: -992.2s;
  animation-duration: 155.8s;
}

.stars .container:nth-child(18) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.1s;
  animation-duration: 31.7s;
  background: #ffa2ca;
}

.stars .container:nth-child(19) {
  width: 2px;
  height: 2px;
  left: 45vw;
  animation-delay: -992.2s;
  animation-duration: 41.5s;
}

.stars .container:nth-child(19) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.3s;
  animation-duration: 43.5s;
  background: rgba(227, 186, 196, 0.9);
}

.stars .container:nth-child(20) {
  width: 1px;
  height: 1px;
  left: 27vw;
  animation-delay: -985.6s;
  animation-duration: 147.4s;
}

.stars .container:nth-child(20) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.2s;
  animation-duration: 20.6s;
  background: #c897e5;
}

.stars .container:nth-child(21) {
  width: 1px;
  height: 1px;
  left: 93vw;
  animation-delay: -985.8s;
  animation-duration: 371.4s;
}

.stars .container:nth-child(21) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.3s;
  animation-duration: 42.2s;
  background: rgba(243, 175, 171, 0.8);
}

.stars .container:nth-child(22) {
  width: 3px;
  height: 3px;
  left: 2vw;
  animation-delay: -996.4666666667s;
  animation-duration: 106.5333333333s;
}

.stars .container:nth-child(22) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.8s;
  animation-duration: 45.4s;
  background: rgba(226, 255, 206, 0.8);
}

.stars .container:nth-child(23) {
  width: 3px;
  height: 3px;
  left: 71.5vw;
  animation-delay: -996.8s;
  animation-duration: 39.9333333333s;
}

.stars .container:nth-child(23) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43s;
  animation-duration: 20.9s;
  background: rgba(226, 207, 146, 0.7);
}

.stars .container:nth-child(24) {
  width: 2px;
  height: 2px;
  left: 59vw;
  animation-delay: -992.2s;
  animation-duration: 163.3s;
}

.stars .container:nth-child(24) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.7s;
  animation-duration: 19.9s;
  background: rgba(232, 230, 165, 0.8);
}

.stars .container:nth-child(25) {
  width: 2px;
  height: 2px;
  left: 5.5vw;
  animation-delay: -996.8s;
  animation-duration: 195.1s;
}

.stars .container:nth-child(25) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43s;
  animation-duration: 38.8s;
  background: #d0c7f0;
}

.stars .container:nth-child(26) {
  width: 3px;
  height: 3px;
  left: 80vw;
  animation-delay: -993.7333333333s;
  animation-duration: 108.0666666667s;
}

.stars .container:nth-child(26) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47.2s;
  animation-duration: 14.1s;
  background: rgba(209, 241, 187, 0.8);
}

.stars .container:nth-child(27) {
  width: 3px;
  height: 3px;
  left: 69.5vw;
  animation-delay: -995.8s;
  animation-duration: 107.3333333333s;
}

.stars .container:nth-child(27) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47s;
  animation-duration: 33.6s;
  background: #f9bf87;
}

.stars .container:nth-child(28) {
  width: 3px;
  height: 3px;
  left: 27vw;
  animation-delay: -995.4666666667s;
  animation-duration: 89.5333333333s;
}

.stars .container:nth-child(28) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.5s;
  animation-duration: 36.2s;
  background: rgba(233, 191, 176, 0.8);
}

.stars .container:nth-child(29) {
  width: 3px;
  height: 3px;
  left: 6vw;
  animation-delay: -994.2s;
  animation-duration: 115.7333333333s;
}

.stars .container:nth-child(29) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.4s;
  animation-duration: 13.6s;
  background: rgba(240, 253, 175, 0.9);
}

.stars .container:nth-child(30) {
  width: 1px;
  height: 1px;
  left: 97.5vw;
  animation-delay: -991.6s;
  animation-duration: 103.2s;
}

.stars .container:nth-child(30) .star {
  width: inherit;
  height: inherit;
  animation-delay: -44.1s;
  animation-duration: 36.8s;
  background: rgba(221, 225, 225, 0.9);
}

.stars .container:nth-child(31) {
  width: 3px;
  height: 3px;
  left: 74vw;
  animation-delay: -996.7333333333s;
  animation-duration: 97.8s;
}

.stars .container:nth-child(31) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.4s;
  animation-duration: 32.7s;
  background: rgba(200, 161, 125, 0.9);
}

.stars .container:nth-child(32) {
  width: 1px;
  height: 1px;
  left: 62.5vw;
  animation-delay: -984.8s;
  animation-duration: 188s;
}

.stars .container:nth-child(32) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.3s;
  animation-duration: 35.4s;
  background: #f7a8f2;
}

.stars .container:nth-child(33) {
  width: 3px;
  height: 3px;
  left: 45vw;
  animation-delay: -993.9333333333s;
  animation-duration: 97.4666666667s;
}

.stars .container:nth-child(33) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.1s;
  animation-duration: 46.7s;
  background: rgba(241, 215, 243, 0.9);
}

.stars .container:nth-child(34) {
  width: 2px;
  height: 2px;
  left: 45vw;
  animation-delay: -998.1s;
  animation-duration: 124.7s;
}

.stars .container:nth-child(34) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.6s;
  animation-duration: 14s;
  background: #d8df91;
}

.stars .container:nth-child(35) {
  width: 1px;
  height: 1px;
  left: 60vw;
  animation-delay: -997.4s;
  animation-duration: 287.6s;
}

.stars .container:nth-child(35) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.7s;
  animation-duration: 14.8s;
  background: #d3c567;
}

.stars .container:nth-child(36) {
  width: 3px;
  height: 3px;
  left: 48.5vw;
  animation-delay: -996.1333333333s;
  animation-duration: 50.2s;
}

.stars .container:nth-child(36) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.5s;
  animation-duration: 45s;
  background: rgba(242, 174, 131, 0.8);
}

.stars .container:nth-child(37) {
  width: 1px;
  height: 1px;
  left: 76vw;
  animation-delay: -992.6s;
  animation-duration: 298.2s;
}

.stars .container:nth-child(37) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.8s;
  animation-duration: 16.5s;
  background: rgba(207, 242, 216, 0.9);
}

.stars .container:nth-child(38) {
  width: 2px;
  height: 2px;
  left: 41vw;
  animation-delay: -990.9s;
  animation-duration: 143.6s;
}

.stars .container:nth-child(38) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.2s;
  animation-duration: 29.6s;
  background: #e0f570;
}

.stars .container:nth-child(39) {
  width: 2px;
  height: 2px;
  left: 35vw;
  animation-delay: -995.5s;
  animation-duration: 158.5s;
}

.stars .container:nth-child(39) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.7s;
  animation-duration: 25s;
  background: rgba(238, 198, 169, 0.7);
}

.stars .container:nth-child(40) {
  width: 1px;
  height: 1px;
  left: 18.5vw;
  animation-delay: -980.6s;
  animation-duration: 164.2s;
}

.stars .container:nth-child(40) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.4s;
  animation-duration: 9.9s;
  background: rgba(217, 215, 238, 0.7);
}

.stars .container:nth-child(41) {
  width: 1px;
  height: 1px;
  left: 66vw;
  animation-delay: -983.6s;
  animation-duration: 65.6s;
}

.stars .container:nth-child(41) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.1s;
  animation-duration: 19s;
  background: rgba(237, 151, 250, 0.9);
}

.stars .container:nth-child(42) {
  width: 3px;
  height: 3px;
  left: 90.5vw;
  animation-delay: -998s;
  animation-duration: 118.6666666667s;
}

.stars .container:nth-child(42) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.7s;
  animation-duration: 43.5s;
  background: rgba(233, 163, 225, 0.7);
}

.stars .container:nth-child(43) {
  width: 2px;
  height: 2px;
  left: 52vw;
  animation-delay: -992.9s;
  animation-duration: 145.8s;
}

.stars .container:nth-child(43) .star {
  width: inherit;
  height: inherit;
  animation-delay: -44.1s;
  animation-duration: 30.6s;
  background: rgba(218, 220, 172, 0.7);
}

.stars .container:nth-child(44) {
  width: 3px;
  height: 3px;
  left: 85vw;
  animation-delay: -998.8s;
  animation-duration: 106.3333333333s;
}

.stars .container:nth-child(44) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43s;
  animation-duration: 41.6s;
  background: rgba(237, 251, 220, 0.8);
}

.stars .container:nth-child(45) {
  width: 2px;
  height: 2px;
  left: 17.5vw;
  animation-delay: -998.6s;
  animation-duration: 63.5s;
}

.stars .container:nth-child(45) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.5s;
  animation-duration: 13s;
  background: #f7f9a1;
}

.stars .container:nth-child(46) {
  width: 2px;
  height: 2px;
  left: 30.5vw;
  animation-delay: -990.2s;
  animation-duration: 174.6s;
}

.stars .container:nth-child(46) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.8s;
  animation-duration: 40.8s;
  background: rgba(209, 206, 153, 0.8);
}

.stars .container:nth-child(47) {
  width: 2px;
  height: 2px;
  left: 36.5vw;
  animation-delay: -990.5s;
  animation-duration: 121.6s;
}

.stars .container:nth-child(47) .star {
  width: inherit;
  height: inherit;
  animation-delay: -41s;
  animation-duration: 13.1s;
  background: rgba(250, 229, 181, 0.9);
}

.stars .container:nth-child(48) {
  width: 3px;
  height: 3px;
  left: 19vw;
  animation-delay: -998.2s;
  animation-duration: 97s;
}

.stars .container:nth-child(48) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.8s;
  animation-duration: 20.8s;
  background: #e1db6d;
}

.stars .container:nth-child(49) {
  width: 2px;
  height: 2px;
  left: 70.5vw;
  animation-delay: -998.3s;
  animation-duration: 53.2s;
}

.stars .container:nth-child(49) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.1s;
  animation-duration: 21.9s;
  background: rgba(239, 183, 223, 0.8);
}

.stars .container:nth-child(50) {
  width: 1px;
  height: 1px;
  left: 8.5vw;
  animation-delay: -989.6s;
  animation-duration: 359.4s;
}

.stars .container:nth-child(50) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.3s;
  animation-duration: 6.3s;
  background: rgba(244, 201, 110, 0.9);
}

.stars .container:nth-child(51) {
  width: 1px;
  height: 1px;
  left: 27.5vw;
  animation-delay: -993.4s;
  animation-duration: 251.4s;
}

.stars .container:nth-child(51) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.5s;
  animation-duration: 23.7s;
  background: rgba(228, 230, 197, 0.8);
}

.stars .container:nth-child(52) {
  width: 1px;
  height: 1px;
  left: 4vw;
  animation-delay: -994.2s;
  animation-duration: 133.6s;
}

.stars .container:nth-child(52) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.8s;
  animation-duration: 27.9s;
  background: rgba(218, 185, 143, 0.8);
}

.stars .container:nth-child(53) {
  width: 1px;
  height: 1px;
  left: 25.5vw;
  animation-delay: -981.8s;
  animation-duration: 147.6s;
}

.stars .container:nth-child(53) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.3s;
  animation-duration: 37.6s;
  background: rgba(252, 235, 176, 0.7);
}

.stars .container:nth-child(54) {
  width: 1px;
  height: 1px;
  left: 16.5vw;
  animation-delay: -989.8s;
  animation-duration: 82s;
}

.stars .container:nth-child(54) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.4s;
  animation-duration: 40.1s;
  background: #e0a264;
}

.stars .container:nth-child(55) {
  width: 3px;
  height: 3px;
  left: 5vw;
  animation-delay: -995.4666666667s;
  animation-duration: 103.4666666667s;
}

.stars .container:nth-child(55) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.8s;
  animation-duration: 10.1s;
  background: rgba(245, 190, 113, 0.8);
}

.stars .container:nth-child(56) {
  width: 2px;
  height: 2px;
  left: 27vw;
  animation-delay: -998.4s;
  animation-duration: 179.4s;
}

.stars .container:nth-child(56) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.8s;
  animation-duration: 37.3s;
  background: rgba(214, 199, 187, 0.7);
}

.stars .container:nth-child(57) {
  width: 1px;
  height: 1px;
  left: 69vw;
  animation-delay: -994.4s;
  animation-duration: 285.2s;
}

.stars .container:nth-child(57) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.5s;
  animation-duration: 41s;
  background: rgba(249, 251, 252, 0.7);
}

.stars .container:nth-child(58) {
  width: 2px;
  height: 2px;
  left: 67.5vw;
  animation-delay: -995.6s;
  animation-duration: 101s;
}

.stars .container:nth-child(58) .star {
  width: inherit;
  height: inherit;
  animation-delay: -44.8s;
  animation-duration: 36.4s;
  background: rgba(241, 166, 140, 0.7);
}

.stars .container:nth-child(59) {
  width: 3px;
  height: 3px;
  left: 31.5vw;
  animation-delay: -996.4666666667s;
  animation-duration: 46.8666666667s;
}

.stars .container:nth-child(59) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.8s;
  animation-duration: 38.4s;
  background: rgba(230, 204, 211, 0.9);
}

.stars .container:nth-child(60) {
  width: 2px;
  height: 2px;
  left: 20.5vw;
  animation-delay: -992.7s;
  animation-duration: 183.3s;
}

.stars .container:nth-child(60) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.8s;
  animation-duration: 37.4s;
  background: rgba(252, 166, 162, 0.9);
}

.stars .container:nth-child(61) {
  width: 2px;
  height: 2px;
  left: 1.5vw;
  animation-delay: -999.4s;
  animation-duration: 146.6s;
}

.stars .container:nth-child(61) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47.3s;
  animation-duration: 11.6s;
  background: rgba(210, 249, 225, 0.9);
}

.stars .container:nth-child(62) {
  width: 1px;
  height: 1px;
  left: 26.5vw;
  animation-delay: -993s;
  animation-duration: 248.6s;
}

.stars .container:nth-child(62) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.8s;
  animation-duration: 10.7s;
  background: #e59d9f;
}

.stars .container:nth-child(63) {
  width: 3px;
  height: 3px;
  left: 60.5vw;
  animation-delay: -993.6666666667s;
  animation-duration: 113.5333333333s;
}

.stars .container:nth-child(63) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.3s;
  animation-duration: 28.6s;
  background: #fe9a9f;
}

.stars .container:nth-child(64) {
  width: 3px;
  height: 3px;
  left: 79.5vw;
  animation-delay: -999s;
  animation-duration: 65.2s;
}

.stars .container:nth-child(64) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47.2s;
  animation-duration: 12.5s;
  background: rgba(207, 213, 188, 0.8);
}

.stars .container:nth-child(65) {
  width: 3px;
  height: 3px;
  left: 27.5vw;
  animation-delay: -996.3333333333s;
  animation-duration: 133.3333333333s;
}

.stars .container:nth-child(65) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.9s;
  animation-duration: 37.4s;
  background: rgba(212, 241, 209, 0.9);
}

.stars .container:nth-child(66) {
  width: 1px;
  height: 1px;
  left: 52.5vw;
  animation-delay: -982s;
  animation-duration: 48.8s;
}

.stars .container:nth-child(66) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.3s;
  animation-duration: 16.2s;
  background: rgba(225, 235, 118, 0.7);
}

.stars .container:nth-child(67) {
  width: 3px;
  height: 3px;
  left: 59vw;
  animation-delay: -998.3333333333s;
  animation-duration: 125.5333333333s;
}

.stars .container:nth-child(67) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.1s;
  animation-duration: 21.3s;
  background: #fdeee4;
}

.stars .container:nth-child(68) {
  width: 1px;
  height: 1px;
  left: 12vw;
  animation-delay: -998.6s;
  animation-duration: 307s;
}

.stars .container:nth-child(68) .star {
  width: inherit;
  height: inherit;
  animation-delay: -44.4s;
  animation-duration: 32.9s;
  background: #cabcc3;
}

.stars .container:nth-child(69) {
  width: 2px;
  height: 2px;
  left: 97vw;
  animation-delay: -999.7s;
  animation-duration: 23.3s;
}

.stars .container:nth-child(69) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.6s;
  animation-duration: 25s;
  background: rgba(215, 158, 198, 0.8);
}

.stars .container:nth-child(70) {
  width: 3px;
  height: 3px;
  left: 41vw;
  animation-delay: -997.2s;
  animation-duration: 77s;
}

.stars .container:nth-child(70) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47.3s;
  animation-duration: 11.5s;
  background: rgba(206, 216, 117, 0.8);
}

.stars .container:nth-child(71) {
  width: 3px;
  height: 3px;
  left: 67.5vw;
  animation-delay: -998.0666666667s;
  animation-duration: 16.8666666667s;
}

.stars .container:nth-child(71) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.3s;
  animation-duration: 14.6s;
  background: #d4c7b9;
}

.stars .container:nth-child(72) {
  width: 3px;
  height: 3px;
  left: 16.5vw;
  animation-delay: -996.4666666667s;
  animation-duration: 121.5333333333s;
}

.stars .container:nth-child(72) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43s;
  animation-duration: 20.9s;
  background: #cbe4c4;
}

.stars .container:nth-child(73) {
  width: 2px;
  height: 2px;
  left: 68.5vw;
  animation-delay: -990.5s;
  animation-duration: 175.6s;
}

.stars .container:nth-child(73) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.2s;
  animation-duration: 31s;
  background: rgba(240, 154, 226, 0.7);
}

.stars .container:nth-child(74) {
  width: 3px;
  height: 3px;
  left: 47.5vw;
  animation-delay: -999.4s;
  animation-duration: 72s;
}

.stars .container:nth-child(74) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.4s;
  animation-duration: 46.3s;
  background: rgba(247, 170, 196, 0.7);
}

.stars .container:nth-child(75) {
  width: 1px;
  height: 1px;
  left: 31vw;
  animation-delay: -988.2s;
  animation-duration: 59.8s;
}

.stars .container:nth-child(75) .star {
  width: inherit;
  height: inherit;
  animation-delay: -41.4s;
  animation-duration: 42.4s;
  background: rgba(219, 213, 142, 0.7);
}

.stars .container:nth-child(76) {
  width: 2px;
  height: 2px;
  left: 47.5vw;
  animation-delay: -993.5s;
  animation-duration: 198.2s;
}

.stars .container:nth-child(76) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.4s;
  animation-duration: 39.9s;
  background: rgba(247, 220, 196, 0.7);
}

.stars .container:nth-child(77) {
  width: 1px;
  height: 1px;
  left: 76vw;
  animation-delay: -997.6s;
  animation-duration: 183.8s;
}

.stars .container:nth-child(77) .star {
  width: inherit;
  height: inherit;
  animation-delay: -49.2s;
  animation-duration: 39.6s;
  background: rgba(250, 188, 169, 0.9);
}

.stars .container:nth-child(78) {
  width: 2px;
  height: 2px;
  left: 99.5vw;
  animation-delay: -999.5s;
  animation-duration: 35.1s;
}

.stars .container:nth-child(78) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.7s;
  animation-duration: 32.5s;
  background: rgba(200, 157, 225, 0.9);
}

.stars .container:nth-child(79) {
  width: 3px;
  height: 3px;
  left: 97vw;
  animation-delay: -997.4666666667s;
  animation-duration: 133.0666666667s;
}

.stars .container:nth-child(79) .star {
  width: inherit;
  height: inherit;
  animation-delay: -41s;
  animation-duration: 21.4s;
  background: #d3a882;
}

.stars .container:nth-child(80) {
  width: 1px;
  height: 1px;
  left: 79vw;
  animation-delay: -993.6s;
  animation-duration: 314.6s;
}

.stars .container:nth-child(80) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.7s;
  animation-duration: 27.8s;
  background: rgba(235, 202, 141, 0.9);
}

.stars .container:nth-child(81) {
  width: 2px;
  height: 2px;
  left: 50vw;
  animation-delay: -995.1s;
  animation-duration: 129.4s;
}

.stars .container:nth-child(81) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.1s;
  animation-duration: 31.4s;
  background: rgba(243, 228, 171, 0.9);
}

.stars .container:nth-child(82) {
  width: 1px;
  height: 1px;
  left: 11.5vw;
  animation-delay: -992.6s;
  animation-duration: 372.4s;
}

.stars .container:nth-child(82) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.3s;
  animation-duration: 50s;
  background: rgba(241, 245, 187, 0.7);
}

.stars .container:nth-child(83) {
  width: 2px;
  height: 2px;
  left: 33.5vw;
  animation-delay: -997.5s;
  animation-duration: 188.6s;
}

.stars .container:nth-child(83) .star {
  width: inherit;
  height: inherit;
  animation-delay: -48.4s;
  animation-duration: 22s;
  background: rgba(237, 191, 108, 0.7);
}

.stars .container:nth-child(84) {
  width: 2px;
  height: 2px;
  left: 42.5vw;
  animation-delay: -998.1s;
  animation-duration: 157s;
}

.stars .container:nth-child(84) .star {
  width: inherit;
  height: inherit;
  animation-delay: -41.2s;
  animation-duration: 30.4s;
  background: rgba(247, 243, 251, 0.8);
}

.stars .container:nth-child(85) {
  width: 2px;
  height: 2px;
  left: 61vw;
  animation-delay: -991.5s;
  animation-duration: 79.4s;
}

.stars .container:nth-child(85) .star {
  width: inherit;
  height: inherit;
  animation-delay: -46.2s;
  animation-duration: 44s;
  background: #d3f7f8;
}

.stars .container:nth-child(86) {
  width: 2px;
  height: 2px;
  left: 89vw;
  animation-delay: -997.6s;
  animation-duration: 113.6s;
}

.stars .container:nth-child(86) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.8s;
  animation-duration: 34.8s;
  background: rgba(214, 190, 198, 0.8);
}

.stars .container:nth-child(87) {
  width: 2px;
  height: 2px;
  left: 22.5vw;
  animation-delay: -993.1s;
  animation-duration: 148.9s;
}

.stars .container:nth-child(87) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47.5s;
  animation-duration: 45.7s;
  background: #f8b7b1;
}

.stars .container:nth-child(88) {
  width: 3px;
  height: 3px;
  left: 52vw;
  animation-delay: -999.9333333333s;
  animation-duration: 119.6s;
}

.stars .container:nth-child(88) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.7s;
  animation-duration: 26.7s;
  background: rgba(255, 166, 158, 0.7);
}

.stars .container:nth-child(89) {
  width: 2px;
  height: 2px;
  left: 49.5vw;
  animation-delay: -993.7s;
  animation-duration: 73.5s;
}

.stars .container:nth-child(89) .star {
  width: inherit;
  height: inherit;
  animation-delay: -41.1s;
  animation-duration: 39s;
  background: rgba(203, 161, 231, 0.9);
}

.stars .container:nth-child(90) {
  width: 3px;
  height: 3px;
  left: 74.5vw;
  animation-delay: -998.6s;
  animation-duration: 27s;
}

.stars .container:nth-child(90) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.7s;
  animation-duration: 14.6s;
  background: rgba(210, 155, 226, 0.9);
}

.stars .container:nth-child(91) {
  width: 2px;
  height: 2px;
  left: 30vw;
  animation-delay: -997.4s;
  animation-duration: 44.3s;
}

.stars .container:nth-child(91) .star {
  width: inherit;
  height: inherit;
  animation-delay: -47s;
  animation-duration: 5.5s;
  background: rgba(248, 178, 209, 0.8);
}

.stars .container:nth-child(92) {
  width: 3px;
  height: 3px;
  left: 59.5vw;
  animation-delay: -996.3333333333s;
  animation-duration: 103.7333333333s;
}

.stars .container:nth-child(92) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.2s;
  animation-duration: 49.8s;
  background: #fef0e5;
}

.stars .container:nth-child(93) {
  width: 1px;
  height: 1px;
  left: 29.5vw;
  animation-delay: -989.6s;
  animation-duration: 388s;
}

.stars .container:nth-child(93) .star {
  width: inherit;
  height: inherit;
  animation-delay: -44.5s;
  animation-duration: 13s;
  background: rgba(250, 240, 244, 0.9);
}

.stars .container:nth-child(94) {
  width: 2px;
  height: 2px;
  left: 81.5vw;
  animation-delay: -999.8s;
  animation-duration: 97.6s;
}

.stars .container:nth-child(94) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.8s;
  animation-duration: 49.7s;
  background: rgba(249, 210, 245, 0.9);
}

.stars .container:nth-child(95) {
  width: 1px;
  height: 1px;
  left: 91.5vw;
  animation-delay: -989.4s;
  animation-duration: 280.2s;
}

.stars .container:nth-child(95) .star {
  width: inherit;
  height: inherit;
  animation-delay: -40.6s;
  animation-duration: 46.6s;
  background: rgba(249, 165, 140, 0.7);
}

.stars .container:nth-child(96) {
  width: 3px;
  height: 3px;
  left: 18vw;
  animation-delay: -995s;
  animation-duration: 59.4666666667s;
}

.stars .container:nth-child(96) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.4s;
  animation-duration: 20.2s;
  background: rgba(246, 202, 223, 0.7);
}

.stars .container:nth-child(97) {
  width: 1px;
  height: 1px;
  left: 35.5vw;
  animation-delay: -986.8s;
  animation-duration: 265.2s;
}

.stars .container:nth-child(97) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42s;
  animation-duration: 14.2s;
  background: rgba(237, 241, 213, 0.7);
}

.stars .container:nth-child(98) {
  width: 1px;
  height: 1px;
  left: 96.5vw;
  animation-delay: -993.6s;
  animation-duration: 78.8s;
}

.stars .container:nth-child(98) .star {
  width: inherit;
  height: inherit;
  animation-delay: -45.9s;
  animation-duration: 16.3s;
  background: rgba(220, 230, 178, 0.9);
}

.stars .container:nth-child(99) {
  width: 1px;
  height: 1px;
  left: 13vw;
  animation-delay: -980.8s;
  animation-duration: 397.6s;
}

.stars .container:nth-child(99) .star {
  width: inherit;
  height: inherit;
  animation-delay: -43.4s;
  animation-duration: 26.1s;
  background: rgba(237, 155, 184, 0.7);
}

.stars .container:nth-child(100) {
  width: 2px;
  height: 2px;
  left: 4vw;
  animation-delay: -992.2s;
  animation-duration: 37.7s;
}

.stars .container:nth-child(100) .star {
  width: inherit;
  height: inherit;
  animation-delay: -42.7s;
  animation-duration: 6.7s;
  background: rgba(225, 245, 198, 0.9);
}
@keyframes stars {
  0% {
    transform: translateY(110vh) translateZ(0);
  }
  100% {
    transform: translateY(-10vh) translateZ(0);
  }
}
@keyframes twinkle {
  0%, 80%, 100% {
    opacity: 0.7;
    box-shadow: 0 0 0 #fff, 0 0 0 #fff;
  }
  95% {
    opacity: 1;
    box-shadow: 0 0 2px #fff, 0 0 4px #fff;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.star-card {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 194px;
  justify-content: center;
  margin: 0 10px 10px 0;
  opacity: 0;
  padding: 10px 0 0 0;
  width: calc(50% - 10px);
  animation: star-card 0.2s ease-in;
  animation-fill-mode: forwards;
}
.star-card--disable {
  cursor: default;
  opacity: 0.7;
}
@keyframes star-card {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.star-card__avatar {
  align-items: center;
  background-color: #F5F5F5;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  min-height: 120px;
  min-width: 120px;
}
.star-card__avatar--disable {
  opacity: 0.5;
}
.star-card__avatar-name {
  font-size: 12px;
  margin-top: 5px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;
}
.star-card__avatar--no-image {
  align-items: center;
  color: #7C8089;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 120px;
  width: 100%;
}
.star-card__avatar--no-image--disable {
  opacity: 0.5;
}
.star-card__avatar-image {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.star-card__footer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  padding: 0 10px;
  width: 100%;
}
.star-card__footer--disable {
  opacity: 0.5;
}
.star-card__footer-image {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 7px;
  overflow: hidden;
  width: 30px;
}
.star-card__footer-name {
  color: #FFFFFF;
  flex: 1 1;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.living-values {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1;
}
.living-values__header {
  padding: 20px;
  position: relative;
}
.living-values__title {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.living-values__description {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.living-values__values {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100% - 160px);
  overflow-y: auto;
  scrollbar-color: #0b0c38 #151758;
  scrollbar-width: thin;
  padding: 0 10px 0 27px;
}
.living-values__button {
  background: #151758;
  bottom: 0;
  left: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.simple-view {
  display: flex;
  flex-direction: column;
  height: 666px;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.simple-view__header {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 0 40px;
  text-align: center;
}
.simple-view__logo {
  display: block;
  margin: auto;
}
.simple-view__title {
  font-size: 22px;
  font-weight: 500;
  margin: 30px 0 10px 0;
  text-transform: uppercase;
}
.simple-view__description {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgba(255, 255, 255, 0.8);
}
.simple-view__footer {
  margin-bottom: 20px;
}
.simple-view__footer-button {
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
}
.simple-view__footer-button:nth-child(2) {
  margin-bottom: 5px;
}
.simple-view__footer-button--transparent {
  height: 30px;
  line-height: 30px;
}
.simple-view__footer-button--transparent:hover {
  color: #FFFFFF;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.pick-user {
  height: 100%;
  background: #232323;
  position: relative;
  z-index: 1;
  /* OVERRIDE SEARCH RESULT BOX STYLES */
}
.pick-user__close {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}
.pick-user__close-icon {
  font-size: 14px;
}
.pick-user__card {
  border-radius: 0;
  cursor: default;
  height: 250px;
  margin: 0;
  width: 100%;
}
.pick-user__search .search-result-list {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  max-height: 225px;
  overflow: auto;
  top: 48px;
  z-index: 2;
}
.pick-user .suite-search-box__input {
  background-color: #232323;
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
}
.pick-user .ui-components .suite-search-box__input-button--green {
  background: #232323;
}
.pick-user .search-result-list {
  background: #232323;
  border-top: none;
  box-shadow: none;
  max-height: 368px;
}
.pick-user .search-result-item .avatar-media .heading {
  color: #FFFFFF;
  font-weight: 400;
}
.pick-user .search-result-item:hover, .pick-user .search-result-item--focused {
  background: #424242;
}
@media only screen and (max-width: 768px) {
  .pick-user .search-result-list {
    position: absolute;
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-star-view {
  display: flex;
  flex-direction: column;
  height: 666px;
  position: relative;
  z-index: 1;
}
.send-star-view__header {
  padding: 20px;
}
.send-star-view__header-back {
  cursor: pointer;
  position: absolute;
  top: 22px;
  z-index: 1;
}
.send-star-view__title {
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
}
.send-star-view__description {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgba(255, 255, 255, 0.8);
}
.send-star-view__values {
  height: 100%;
  max-height: calc(100% - 160px);
  margin: 0 20px 70px 20px;
  overflow-y: auto;
  scrollbar-color: #0b0c38 #151758;
  scrollbar-width: thin;
}
.send-star-view__values-discovery {
  z-index: 1000;
}
.send-star-view__button {
  background: #151758;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  padding: 20px;
  text-transform: uppercase;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-card {
  background-color: #fbfbfb;
  border-radius: 7px;
  color: #18181A;
  display: flex;
  flex-direction: column;
  min-height: 188px;
  margin-bottom: 10px;
  width: 100%;
}
.send-card__content {
  background-color: #FFFFFF;
  border-radius: 7px;
  display: flex;
  padding: 15px 10px;
}
.send-card__content-image {
  background-size: cover;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
  flex: none;
  height: 108px;
  margin-right: 10px;
  width: 90px;
}
.send-card__content-info {
  overflow: hidden;
  width: 100%;
}
.send-card__content-name {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 17px;
}
.send-card__content-value {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}
.send-card__content-value-image {
  border-radius: 50%;
  display: flex;
  margin-right: 5px;
}
.send-card__content-value-image-img {
  border-radius: 50%;
  overflow: hidden;
}
.send-card__content-value-name {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.send-card__content-default {
  color: #7C8089;
  cursor: default;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-bottom: 1px;
}
.send-card__content-text {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #DEE0E3;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin: 6px 0 10px 0;
  padding: 8px 10px;
}
.send-card__footer {
  height: 45px;
}
.smu-nav-menu {
  height: calc(100vh - 64px);
  left: 0;
  position: fixed;
  top: 64px;
  z-index: 11;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.onboarding-modal {
  background-color: #FFFFFF;
  border-radius: 10px;
  height: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-send-star-flow-confirmation__origin-SURVEYS, .smu-send-star-flow-confirmation__origin-YIR {
  background-color: #FFFFFF;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.smu-send-star-flow-confirmation__origin-SURVEYS .confirmation__header, .smu-send-star-flow-confirmation__origin-YIR .confirmation__header {
  padding: 30px;
}
.smu-send-star-flow-confirmation__origin-SURVEYS .confirmation__header-content, .smu-send-star-flow-confirmation__origin-YIR .confirmation__header-content {
  max-width: 100%;
}
.smu-send-star-flow-confirmation__origin-SURVEYS .confirmation__footer-message-container, .smu-send-star-flow-confirmation__origin-YIR .confirmation__footer-message-container {
  text-align: left;
}
.smu-send-star-flow-confirmation__origin-SURVEYS {
  height: calc(100% - 80px);
}
.smu-send-star-flow-confirmation__origin-SURVEYS .confirmation__footer-message-container {
  padding: 30px 30px 0 30px;
}
.smu-send-star-flow-confirmation__origin-YIR {
  height: 100%;
}
.smu-send-star-flow-confirmation__origin-YIR .confirmation__footer-message-container {
  padding: 30px;
}
.smu-send-star-flow-confirmation__yourself {
  background-image: url("/assets/img/bg_yourself.svg");
  background-position: center;
  background-size: cover;
  position: relative;
}
.smu-send-star-flow-confirmation__yourself::after {
  background-image: url("/assets/img/be_proud.svg");
  background-size: 100%;
  bottom: 30px;
  content: "";
  height: 45px;
  position: absolute;
  right: 30px;
  width: 110px;
  z-index: 1;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-star__status-bar {
  background-color: #FFFFFF;
  bottom: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 70px;
  left: 0;
  line-height: 70px;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 991;
}
.send-star__status-bar--progress {
  animation: send-star-progress 4s ease;
  height: 7px;
  left: 0;
  position: absolute;
  top: 0;
}
@keyframes send-star-progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.smu-send-star-sekeleton {
  background-color: white;
  height: 100%;
  width: 100%;
}
.smu-send-star-sekeleton__content {
  margin: 0 auto;
  max-width: 700px;
  width: 60%;
}
.smu-send-star-sekeleton__header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.smu-send-star-sekeleton__header-remaining {
  margin-bottom: 6px;
}
.smu-send-star-sekeleton__header-count {
  margin-left: auto;
}
.smu-send-star-sekeleton__who {
  margin-bottom: 40px;
}
.smu-send-star-sekeleton__who-title {
  margin-bottom: 6px;
}
.smu-send-star-sekeleton__who-input {
  width: 100%;
}
.smu-send-star-sekeleton__what {
  margin-bottom: 40px;
}
.smu-send-star-sekeleton__what-title {
  margin-bottom: 6px;
}
.smu-send-star-sekeleton__what-input {
  width: 100%;
}
.smu-send-star-sekeleton__why {
  margin-bottom: 10px;
}
.smu-send-star-sekeleton__why-title {
  margin-bottom: 6px;
}
.smu-send-star-sekeleton__why-input {
  width: 100%;
}
.smu-send-star-sekeleton__actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.smu-send-star-sekeleton__actions-cancel {
  margin-right: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-send-star {
  animation: slide-from-top 0.4s ease-in-out;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: translateY(-100%);
}
@keyframes slide-from-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.smu-send-star__origin-SURVEYS, .smu-send-star__origin-YIR {
  animation: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: none;
  width: 100%;
  z-index: 10;
}
.smu-send-star__origin-SURVEYS .star-container, .smu-send-star__origin-YIR .star-container {
  padding: 0 25px;
  width: 100%;
}
.smu-send-star__origin-SURVEYS .remaining-stars-counter, .smu-send-star__origin-YIR .remaining-stars-counter {
  padding-right: 30px;
}
.smu-send-star__origin-SURVEYS .send-star-form__footer-buttons, .smu-send-star__origin-YIR .send-star-form__footer-buttons {
  flex-direction: column;
  width: 100%;
}
.smu-send-star__origin-SURVEYS .send-star-form__footer-buttons button, .smu-send-star__origin-YIR .send-star-form__footer-buttons button {
  width: 100%;
}
.smu-send-star__origin-SURVEYS .send-star-form__footer-buttons button:first-of-type, .smu-send-star__origin-YIR .send-star-form__footer-buttons button:first-of-type {
  margin-bottom: 10px;
  margin-right: 0;
}
.smu-send-star__origin-YIR {
  border-radius: 8px;
  overflow: hidden;
}
.smu-send-stars-modal-error {
  align-items: center;
  background-color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
}
.smu-send-stars-modal-error__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
}
.star-container {
  margin: 0 auto;
  width: 100%;
}
@media screen and (min-width: 900px) {
  .star-container {
    width: 60%;
    max-width: 700px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.what-value {
  border: 1px solid #DEE0E3;
  padding: 10px;
}
.what-value--horizontal {
  align-items: center;
  display: flex;
  height: 75px;
}
.what-value__popup.ui.popup {
  animation: fadeIn 0.2s ease-in;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  background: #18181A;
  border: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 20px;
  white-space: pre-line;
  width: 230px;
}
.what-value__popup.ui.popup.left.center.popup:before, .what-value__popup.ui.popup.right.center.popup:before {
  background: #18181A;
}
.what-value__popup.ui.popup:before {
  background: #18181A;
  box-shadow: none !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-star-form {
  background-color: #FFFFFF;
  flex: 1 1;
  overflow-y: auto;
  padding-bottom: 20px;
}
.send-star-form__carbon-copy {
  padding-top: 10px;
}
.send-star-form__colleague {
  margin-top: 20px;
}
.send-star-form__emojis {
  position: relative;
  z-index: 999;
}
.send-star-form__emojis .emoji-mart {
  right: 0 !important;
  top: -420px !important;
}
.send-star-form__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.send-star-form__footer-error-primary {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.send-star-form__footer-error-secondary {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.send-star-form__footer-buttons button:first-of-type {
  margin-right: 10px;
}
.send-star-form__message {
  margin-top: 20px;
  position: relative;
  width: 100%;
  z-index: 0;
}
.send-star-form__message-container {
  background: #FFFFFF;
  position: relative;
  z-index: 0;
}
.send-star-form__search-result-item, .send-star-form__search-cc-result-item {
  width: 100%;
}
.send-star-form__search-popup.ui.popup, .send-star-form__search-cc-popup.ui.popup {
  background: #18181A;
  border: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 20px;
  white-space: pre-line;
  width: 230px;
}
.send-star-form__search-popup.ui.popup.left.center.popup:before, .send-star-form__search-popup.ui.popup.right.center.popup:before, .send-star-form__search-cc-popup.ui.popup.left.center.popup:before, .send-star-form__search-cc-popup.ui.popup.right.center.popup:before {
  background: #18181A;
}
.send-star-form__search-popup.ui.popup:before, .send-star-form__search-cc-popup.ui.popup:before {
  background: #18181A;
  box-shadow: none !important;
}
.send-star-form__search--kudos-users, .send-star-form__search-cc--kudos-users {
  padding-bottom: 30px;
}
.send-star-form__title.title {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.send-star-form__what {
  background-color: #FFFFFF;
  margin-top: 20px;
}
.send-star-form__what-info {
  border-top: 2px solid #0277a8;
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  width: 100%;
}
.send-star-form__what-info-icon {
  margin-right: 5px;
}
.send-star-form__what-info-icon::before {
  -webkit-text-fill-color: #0277a8;
  -webkit-text-stroke: #0277a8;
  color: #0277a8;
  fill: #0277a8;
  text-stroke: #0277a8;
}
.send-star-form__what-info-wrapper {
  align-items: center;
  color: #0277a8;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.send-star-form__who {
  background: #FFFFFF;
  position: relative;
  z-index: 0;
}
.send-star-form__why {
  background: transparent;
  height: 170px;
  font-size: 14px;
}
.send-star-form__why--ai .input-rich-text__editor {
  padding-right: 50px;
}
.send-star-form__textarea {
  position: relative;
}
.send-star-form .search-results .suite-search-box {
  display: flex;
  align-items: center;
}
.send-star-form .search-results .suite-search-box input {
  border-radius: 3px 0 0 3px;
  border: 1px solid #DEE0E3;
  border-right: 0;
  height: 50px;
  padding: 0 20px;
  margin: 0;
}
.send-star-form .search-results .suite-search-box button {
  align-items: center;
  display: flex;
  flex: none;
  height: 50px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 50px;
}
.send-star-form .search-results .search-result-list {
  background-color: #FFFFFF;
  border: 1px solid #DEE0E3;
  max-height: 180px !important;
  top: 49px;
  z-index: 5;
}
.send-star-form__what .suite-autocomplete .suite-autocomplete__input-wrapper .suite-autocomplete__input-text {
  height: 50px;
  padding: 0 20px;
  width: 100%;
}
.send-star-form__what .suite-autocomplete .suite-autocomplete__input-wrapper .suite-autocomplete__icon-wrapper {
  align-items: center;
  border-left: 1px solid #DEE0E3;
  display: flex;
  flex: none;
  height: 50px;
  justify-content: center;
  width: 50px;
}
.image-preview {
  align-items: center;
  -ms-flex-align: center;
  bottom: 0;
  display: flex;
  display: -ms-flexbox;
  left: 0;
  position: absolute;
  z-index: 5;
}
.image-preview__upload {
  height: 25px;
  margin: 0 10px;
}
.image-preview__icon {
  font-size: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.selected-entities__stereoscope {
  align-content: center;
  display: flex;
  min-height: 100%;
  overflow: auto;
  padding: 4px 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.selected-entities__stereoscope::-webkit-scrollbar {
  display: none;
}
.selected-entities__stereoscope__item {
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  margin: 10px 8px;
  width: 100%;
}
.selected-entities__stereoscope__right, .selected-entities__stereoscope__left {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
}
.selected-entities__stereoscope__right {
  margin-left: 5px;
  transform: rotate(180deg);
}
.selected-entities__stereoscope__left {
  margin-right: 5px;
}
.selected-entities__stereoscope .Stereoscope__item--multiple .selected-entities__user {
  flex-direction: column;
  justify-content: center;
  height: 82px;
}
.selected-entities__stereoscope .Stereoscope__item--multiple .selected-entities__user-name {
  flex: none;
  font-size: 12px;
  margin: 8px 0;
  text-align: center;
}
.selected-entities__stereoscope .Stereoscope__item--multiple .selected-entities__user-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.selected-entities__user {
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  height: 50px;
  overflow: hidden;
  padding: 0 10px;
  padding: 0 20px;
  position: relative;
}
.selected-entities__user-name {
  flex: 1 1;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.selected-entities__user-icon {
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
}
.selected-entities__user-icon::before {
  -webkit-text-fill-color: #7C8089;
  -webkit-text-stroke: #7C8089;
  color: #7C8089;
  fill: #7C8089;
  text-stroke: #7C8089;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.remaining-stars-counter {
  align-items: flex-end;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 900px) {
  .remaining-stars-counter {
    display: none;
  }
}
.remaining-stars-counter__icon {
  margin-left: 10px;
}
.remaining-stars-counter__remaining, .remaining-stars-counter__total {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}
.remaining-stars-counter__remaining {
  font-weight: bolder;
}
.remaining-stars-counter__separator {
  font-size: 18px;
  margin: 0 2px;
}
.remaining-stars-counter__title {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-stretch: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.3;
  margin: 5px 0px;
  text-transform: uppercase;
}
.remaining-stars-counter__values-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-star-header__container {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  position: relative;
  z-index: 0;
}
@media only screen and (max-width: 900px) {
  .send-star-header__container {
    padding: 0px 10px;
  }
}
.send-star-header__message {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding-top: 7px;
  text-transform: uppercase;
}
.send-star-header__close {
  cursor: pointer;
  height: 16px;
  line-height: 16px;
  min-height: 16px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 36px;
}
.send-star-header__close::before {
  -webkit-text-fill-color: #FFFFFF;
  -webkit-text-stroke: #FFFFFF;
  color: #FFFFFF;
  fill: #FFFFFF;
  text-stroke: #FFFFFF;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-suggestions {
  align-items: center;
  border-bottom: 1px solid #DEE0E3;
  display: flex;
  padding: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.send-star-error__content {
  padding: 20px 25px;
}
.send-star-error__content-description {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
}
.send-star-error__content-description > span > b {
  color: #18181A;
}
.send-star-error__content-user-list {
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 330px;
  overflow-y: auto;
  width: 100%;
}
.send-star-error__content-user-list .suite-link {
  pointer-events: none;
  width: 50%;
}
.send-star-error__content-user {
  margin: 10px 15px;
}
.send-star-error__footer {
  align-items: center;
  border-top: 1px solid #EAEAEA;
  display: flex;
  height: 70px;
  padding: 20px 30px;
  width: 100%;
}
.send-star-error__footer-message {
  color: #7C8089;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  max-width: calc(100% - 50px);
  vertical-align: middle;
}
.send-star-error__footer-message-icon {
  display: inline-block;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
  width: 40px;
}
.send-star-error__header {
  align-items: center;
  background-color: #FAFAFA;
  border-bottom: 1px solid #EAEAEA;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  height: 70px;
  padding: 25px;
}
.send-star-error__header-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 5%;
}
.send-star-error__header-title {
  color: #B4B4BE;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  width: 95%;
}
.send-star__photo-widget {
  position: relative;
}
.send-star__photo-widget .photo-widget__upload-icon::before {
  -webkit-text-fill-color: #C2C7CC;
  -webkit-text-stroke: #C2C7CC;
  color: #C2C7CC;
  fill: #C2C7CC;
  text-stroke: #C2C7CC;
}
.send-star__photo-widget .photo-widget__upload-icon--black::before {
  -webkit-text-fill-color: #18181A;
  -webkit-text-stroke: #18181A;
  color: #18181A;
  fill: #18181A;
  text-stroke: #18181A;
}
.send-star__photo-widget .photo-widget__upload-input {
  cursor: pointer;
  font-size: 20px;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 15;
}
.send-star__photo-widget .photo-widget__upload-input::-webkit-file-upload-button {
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 16;
}
.send-star__emoji-widget::before {
  -webkit-text-fill-color: #C2C7CC;
  -webkit-text-stroke: #C2C7CC;
  color: #C2C7CC;
  fill: #C2C7CC;
  text-stroke: #C2C7CC;
}

.smu-send-star-flow {
  position: relative;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-app:not(.smu-app--webview) {
  padding-left: 70px;
}
.smu-app:not(.smu-app--webview).smu-app--open-menu {
  padding-left: 270px;
}

.curtain {
  background-color: #18181A;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  position: fixed;
  right: 0;
  z-index: 900;
}

.ui-navbar__menu-mobile-trigger {
  display: none;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-app-loader {
  align-items: center;
  background: #f0f2f5;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.suite-text-field {
  border-radius: 3px;
  display: inline-flex;
  min-height: 50px;
}
.suite-text-field__input {
  background-color: transparent;
  border-bottom: solid 1px #dee0e3;
  border: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.15;
  min-height: 19px;
  min-width: 10px;
  padding: 17px 20px;
  width: 100%;
}
.suite-text-field--full-width {
  width: 100%;
}
.suite-text-field--size-small {
  min-height: 30px;
}
.suite-text-field--size-small .suite-text-field__input {
  font-size: 12px;
  line-height: 1.17;
  padding: 8px 10px;
}
.suite-text-field--size-medium {
  min-height: 40px;
}
.suite-text-field--size-medium .suite-text-field__input {
  font-size: 14px;
  padding: 12px 15px;
}
.suite-text-field--size-large {
  min-height: 50px;
}
.suite-text-field--size-large .suite-text-field__input {
  font-size: 14px;
  padding: 17px 20px;
}
.suite-text-field--variant-outlined {
  border: solid 1px #dee0e3;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.advanced-user-filters {
  display: flex;
  flex-direction: column;
  min-height: 88px;
}
.advanced-user-filters__title {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #c2c7cc;
  text-transform: uppercase;
  margin: 0;
}
.advanced-user-filters__loader {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.advanced-user-filters__items {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  width: calc(100% + 16px);
}
.advanced-user-filters__item {
  padding: 8px;
  width: 20%;
}
.advanced-user-filters__filter {
  background-color: #fff;
}
.advanced-user-filters__adornment {
  align-items: center;
  border-left: 1px solid #dee0e3;
  display: flex;
  flex: none;
  justify-content: center;
  width: 40px;
}
.advanced-user-filters__adornment-icon {
  font-size: 16px;
}
.advanced-user-filters__adornment-icon::before {
  -webkit-text-fill-color: #18181a;
  -webkit-text-stroke: #18181a;
  color: #18181a;
  fill: #18181a;
  text-stroke: #18181a;
}
@media (max-width: 991px) {
  .advanced-user-filters__item {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .advanced-user-filters__item {
    width: 50%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.advanced-user-search {
  height: 100%;
}
.advanced-user-search__wrapper {
  width: 100%;
}
.advanced-user-search__empty {
  color: #7c8089;
  font-family: "Roboto", sans-serif;
  text-align: center;
  width: 100%;
}
.advanced-user-search__users {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  width: calc(100% + 16px);
}
.advanced-user-search__user {
  padding: 8px;
  width: 33.3333333333%;
}
.advanced-user-search__info {
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  min-height: 78px;
  padding: 15px;
  transition: 0.2s;
}
.advanced-user-search__info:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}
.advanced-user-search__loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.advanced-user-search--with-users .advanced-user-search__loader {
  height: auto;
}
@media (max-width: 991px) {
  .advanced-user-search__user {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .advanced-user-search__user {
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-advanced-user-search-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 0 34px 0;
}
.smu-advanced-user-search-layout__filters {
  padding: 20px 0;
}
.smu-advanced-user-search-layout__users {
  background-color: white;
  flex: 1 1;
  padding: 40px 0;
}
.smu-advanced-user-search-layout__users > *:first-child {
  height: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-state__image {
  margin: 30px 0px;
}
.empty-state__text-subtitle {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.43;
}
.empty-state__text-title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.19;
  text-transform: uppercase;
}
.empty-state__text-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
}
.skeleton-os-feed > * {
  margin-bottom: 10px;
}
.skeleton-os-feed > *:last-child {
  margin-bottom: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.os-feed__toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 14px 0 12px;
}
.os-feed__toggle-description {
  color: #7C8089;
  font-size: 12px;
  margin: 0;
  margin-right: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-carousel {
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}
.smu-carousel__img {
  width: 100%;
}
.smu-carousel .gallery {
  background-color: #F0F2F5;
}
.smu-carousel .gallery .image-gallery-bullet {
  background-color: #D2D2D2;
  border: none;
  box-shadow: none;
  transform: scale(0.7);
}
.smu-carousel .gallery .image-gallery-bullet:hover {
  -webkit-transform: scale(1);
  background-color: #D2D2D2;
  transform: scale(1);
}
.smu-carousel .gallery .image-gallery-bullet:focus {
  -webkit-transform: scale(1);
  background-color: #D2D2D2;
  transform: scale(0.7);
}
.smu-carousel .gallery .image-gallery-bullet.active {
  background-color: #14125C;
  border: none;
  transform: scale(1.2);
}
.smu-carousel .gallery .image-gallery-bullets {
  bottom: 10px;
  z-index: initial;
}
.smu-carousel .gallery .image-gallery-image {
  cursor: pointer;
}
.smu-carousel .banner-chevron {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  width: 40px;
  z-index: 1;
}
.smu-carousel .banner-chevron__icon {
  filter: brightness(10);
  filter: drop-shadow(0 0 6px 0 #000000);
  height: 15px;
  width: 9px;
}
.smu-carousel .banner-chevron:hover span {
  filter: brightness(0) drop-shadow(0 0 6px #000000);
}
.smu-carousel .banner-chevron__left {
  background-image: linear-gradient(91deg, rgba(0, 0, 0, 0.3) 3%, rgba(0, 0, 0, 0) 75%);
  border-radius: 4px 0 0 4px;
  justify-content: flex-start;
  left: 0;
  padding-left: 10px;
}
.smu-carousel .banner-chevron__left--hidden {
  display: none;
}
.smu-carousel .banner-chevron__left:hover {
  background-image: linear-gradient(91deg, rgba(0, 0, 0, 0.3) 3%, rgba(0, 0, 0, 0) 95%);
}
.smu-carousel .banner-chevron__right {
  background-image: linear-gradient(269deg, rgba(0, 0, 0, 0.3) 3%, rgba(0, 0, 0, 0) 75%);
  border-radius: 0 4px 4px 0;
  justify-content: flex-end;
  padding-right: 10px;
  right: 0;
}
.smu-carousel .banner-chevron__right--hidden {
  display: none;
}
.smu-carousel .banner-chevron__right:hover {
  background-image: linear-gradient(269deg, rgba(0, 0, 0, 0.4) 5%, rgba(255, 255, 255, 0) 95%);
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.error-state__img {
  margin-bottom: 29px;
  max-height: 196px;
  max-width: 208px;
}
.error-state__title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
}
.error-state__description {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 24px;
  text-align: center;
}
.error-state__buttons {
  display: flex;
  justify-content: space-between;
}
.error-state__buttons--margin-right {
  margin-right: 16px;
}
.skeleton-header {
  display: flex;
  margin-top: 38px;
  max-width: 1030px;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.communications-header {
  background: #FFFFFF;
  display: flex;
  margin-top: 38px;
  max-width: 1030px;
  padding-top: 19px;
  margin-left: 0;
  width: 100%;
}
.communications-header__details {
  align-items: center;
  display: flex;
  margin-right: 20px;
  max-width: 715px;
  padding-left: 40px;
  width: 100%;
}
.communications-header__text {
  color: #14145a;
}
.communications-header__title {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.05;
  margin-bottom: 10px;
}
.communications-header__description {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}
.communications-header__link {
  align-items: center;
  background: #f0f2f5;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  height: 40px;
  justify-content: center;
  padding: 0 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.communications-header__link:hover {
  color: #14145a;
}
.communications-header__link--text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.communications-header__link--text:hover {
  text-decoration: none;
}
.communications-header__link--icon {
  margin-left: 10px;
}
.communications-header__image {
  background-position: 0 0;
  background-size: 100%;
  padding-top: 60.99%;
  width: 100%;
}
.communications-header__image-container {
  align-self: flex-end;
  max-width: 315px;
  padding-right: 31px;
  position: relative;
  width: 100%;
}
.main-panel {
  padding: 0 0 40px 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.skeleton-useful-links {
  background: #FFFFFF;
  padding: 16px 5px 0px 16px;
  width: 100%;
}
.skeleton-useful-links__links {
  height: 100%;
  margin: 16px 0;
  max-height: 400px;
  overflow: hidden;
  padding-right: 23px;
}
.skeleton-useful-links__link-container {
  margin: 16px 0;
}
.skeleton-useful-links__link-title {
  margin-bottom: 8px;
}
.skeleton-useful-links__link-title--text {
  margin-right: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.useful-links-widget {
  background: #FFFFFF;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 16px 5px 0px 16px;
}
.useful-links-widget__title {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.useful-links-widget__links {
  height: 100%;
  margin: 16px 0;
  padding-right: 23px;
}
.useful-links-widget__links::-webkit-scrollbar {
  width: 4px;
}
.useful-links-widget__link-container {
  margin: 16px 0;
}
.useful-links-widget__link-container--no-margin-top {
  margin-top: 0;
}
.useful-links-widget__link-container--no-margin-bottom {
  margin-bottom: 0;
}
.useful-links-widget__link-title {
  cursor: pointer;
  margin-bottom: 8px;
}
.useful-links-widget__link-title--text {
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  text-decoration: underline;
  text-transform: capitalize;
}
.useful-links-widget__link-title--text:hover {
  text-decoration: none;
}
.useful-links-widget__link-description {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  min-height: 20px;
}
.useful-links-widget__link--icon {
  margin-bottom: 3px;
}
.useful-links-widget__divider {
  background: #F0F2F5;
  height: 1px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.communications {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: 0 24px;
  width: 100%;
}
.communications__content {
  display: flex;
  justify-content: center;
  max-width: 1030px;
  padding-top: 24px;
  width: 100%;
}
.communications__content--error {
  padding: 0;
}
.communications__feed {
  margin-right: 20px;
  max-width: 715px;
  width: 100%;
}
.communications__widget-container {
  display: flex;
  flex-direction: column;
  max-width: 315px;
  width: 100%;
}
.communications__community-customize {
  margin-bottom: 20px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.community-guidelines {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.community-guidelines__header {
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 4px 2px -2px #ccc;
  display: flex;
  justify-content: center;
  min-height: 50px;
}
.community-guidelines__header-content {
  align-items: center;
  display: flex;
  height: 100%;
  max-width: 1170px;
  width: 100%;
}
.community-guidelines__content {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 60px;
  padding: 15px;
  width: 100%;
}
.community-guidelines__result {
  max-width: 1170px;
}
.community-guidelines__footer {
  align-items: center;
  background: #000000;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: auto;
  min-height: 95px;
  padding: 30px 0;
  position: relative;
  width: 100%;
}
.community-guidelines__footer-content {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
}
.components-layout {
  min-height: 100vh;
  padding: 60px 0 34px 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  margin: 0 auto;
  max-width: 610px;
  padding: 65px;
}
.smu-empty-state__button {
  margin-top: 20px;
}
.smu-empty-state__image {
  height: auto;
  margin-bottom: 20px;
  width: 250px;
}
.smu-empty-state__subtitle {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 5px;
  text-align: center;
}
.smu-empty-state__title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  max-width: 300px;
  text-align: center;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.bt-feed__card {
  margin-bottom: 10px;
}
.bt-feed__header {
  align-items: center;
  display: flex;
  font-family: "Roboto", sans-serif;
  justify-content: space-between;
  margin-bottom: 15px;
}
.bt-feed__header-title {
  font-size: 16px;
  font-weight: 700;
  padding-right: 10px;
  text-transform: uppercase;
  word-break: break-word;
}
.bt-feed__header-total {
  color: #7C8089;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.feed-sort-toggle {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.33);
  color: #000;
  overflow: hidden;
}
.feed-sort-toggle__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  line-height: 22px;
  padding: 10px 15px 15px 15px;
  text-align: center;
}
.feed-sort-toggle__header-icon {
  margin: 15px 0;
}
.feed-sort-toggle__footer {
  border-top: 1px solid #dee0e3;
  cursor: pointer;
  display: flex;
  line-height: 20px;
  opacity: 1;
  padding: 15px;
  width: 100%;
}
.feed-sort-toggle__footer:hover {
  background-color: #e8e8ff;
}
.feed-sort-toggle__footer-icon {
  flex: none;
  font-size: 20px;
  margin-right: 12px;
  margin-top: 2px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.feed-sort-modal__content {
  left: 0;
  position: absolute;
  top: 0;
}
.feed-sort-modal__content::after {
  background-color: white;
  border-radius: 2px;
  content: "";
  height: 14px;
  opacity: 1;
  position: absolute;
  right: 10px;
  top: -4px;
  transform: matrix(-0.71, 0.71, -0.71, -0.71, 0, 0);
  width: 14px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.feed-sort-button {
  border-radius: 33px;
  height: 33px;
  padding: 3px;
  width: 33px;
}
.feed-sort-button:hover {
  background-color: #dedeff;
  opacity: 1;
}
.feed-sort-button__popup.ui.popup {
  font-size: 12px;
  padding: 5px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-feed-filters {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.smu-feed-filters__chips {
  align-items: center;
  display: flex;
}
.smu-feed-filters__chip {
  margin-right: 13px;
  padding: 6px 16px;
  transition: 0.3s;
}
.smu-feed-filters__chip--active {
  cursor: default;
}
.smu-feed-filters__chip:hover:not(.smu-feed-filters__chip--active) {
  background-color: #dedeff;
}
.smu-feed-filters__chip:last-child {
  margin-right: 0;
}
.smu-feed-filters__feed-sort-button {
  margin-left: 13px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.feedv2__filters {
  margin-bottom: 20px;
}
.feedv2__tabs-filter {
  display: flex;
  overflow: hidden;
}
.feedv2__tabs-filter-button {
  background: transparent;
  width: 100px;
  border-radius: 0 !important;
  border-left: 1px solid #DEE0E3 !important;
}
.feedv2__tabs-filter-button:hover {
  background: #FAFAFA;
}
.feedv2__tabs-filter-button--filters-applied {
  background-color: #18181A !important;
}
.feedv2__tabs-filter-button--filters-applied:hover {
  background: #18181A;
}
.feedv2__tabs-filter .tab-panel__integrations {
  display: inline-block;
  vertical-align: middle;
}
.feedv2__tabs-filter .tab-panel__integrations small {
  font-size: 8px;
}
.feedv2-toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 14px 0 12px;
}
.feedv2-toggle__description {
  color: #7C8089;
  font-size: 12px;
  margin: 0;
  margin-right: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.activity {
  align-items: center;
  display: flex;
  min-height: 40px;
  margin-bottom: 20px;
}
.activity:last-child {
  margin: 0;
}
.activity__avatar {
  position: relative;
}
.activity__avatar-image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.activity__avatar-action {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  padding: 6px;
  position: absolute;
  right: -20px;
  top: 8px;
}
.activity__avatar-star {
  width: 14px;
}
.activity__avatar-hearth {
  color: #E35252;
  font-size: 12px;
  line-height: 1.19;
  position: relative;
  top: 1px;
  width: 14px;
}
.activity__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
}
.activity__body-name {
  margin: 0;
  font-size: 12px;
}
.activity__body-job {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.fullscreen-modal--custom-interacion {
  height: 100%;
  top: 0;
  z-index: 1400;
}
.fullscreen-modal--custom-interacion .fullscreen-modal__content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.interaction__modal {
  padding: 0;
  width: 610px;
}
.interaction__modal-title {
  color: #888888;
  font-family: Montserrat;
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
}
.interaction__modal-header {
  align-items: center;
  display: flex;
  padding: 30px;
  position: relative;
}
.interaction__modal-header-close {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}
.interaction__modal-tabs {
  border-bottom: 1px solid #dee0e3;
  border-top: 1px solid #dee0e3;
}
.interaction__modal-tabs-extra-tab-className {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100px;
}
.interaction__modal-tabs-star {
  width: 14px;
  margin-right: 5px;
}
.interaction__modal-tabs-hearth {
  margin-right: 5px;
}
.interaction__modal-body {
  height: -webkit-min-content;
  height: min-content;
  max-height: 280px;
  overflow-x: hidden;
  padding: 29px 30px;
}
.interaction__modal-loader {
  min-height: 70px;
}
.with-congratulations-feed {
  margin: 0 auto;
  max-width: 610px;
  width: 100%;
}
.with-congratulations-feed .infinite-scroll-component {
  overflow: unset !important;
  overflow-y: auto;
}
.congratulation-feed-layout {
  padding: 60px 0 40px;
}
.congratulation-feed-layout__header {
  margin-bottom: 20px;
}
.congratulation-feed-layout__header * {
  text-transform: uppercase;
}
.congratulation-feed-layout__feed {
  margin: 0 auto;
  max-width: 600px;
  overflow: unset !important;
  overflow-y: auto;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-congratulations .emoji-mart {
  /* OVERRIDE Inline styles. */
  top: -18px !important;
}
.smu-congratulations .infinite-scroll-component {
  overflow: unset !important;
}
.smu-congratulations .star-messages__accordion-header {
  z-index: inherit;
}
.mention-user-skeleton {
  display: flex;
}
.mention-user-skeleton__avatar {
  flex: none;
}
.mention-user-skeleton__wrapper {
  margin-left: 10px;
  width: 100%;
}
.mention-user-skeleton__title {
  margin-bottom: 6px;
}
.mention-user-skeleton__description {
  margin-bottom: 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.user-popup {
  display: flex;
  width: 210px;
}
.user-popup__skeleton {
  width: 100%;
}
.user-popup__image {
  flex: none;
}
.user-popup__info {
  margin-left: 10px;
}
.user-popup__name {
  color: #fff;
  font-size: 14px;
  margin-bottom: 6px;
}
.user-popup__name:hover {
  text-decoration: underline;
}
.user-popup__email {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  margin-bottom: 10px;
}
.user-popup__profile-button {
  border: 1px solid #DEE0E3;
  color: #DEE0E3;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.mentions-popup__profile-button {
  border: 1px solid #DEE0E3;
  color: #DEE0E3;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
}
.mentions-popup__info {
  float: left;
  margin: 0 0 0 10px;
  cursor: default;
}
.mentions-popup__email {
  color: #7C8089;
  font-size: 10px;
  margin: 0 0 10px 0;
  font-family: "Montserrat", sans-serif;
  text-align: left;
}
.mentions-popup__name {
  margin: 0;
  text-transform: capitalize;
  text-align: left;
}
.mentions-popup__image {
  float: left;
}
.mentions-popup__name {
  font-size: 14px;
  cursor: pointer;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.text-with-mentions__hashtags {
  color: #18181A;
  cursor: pointer;
  font-weight: 700;
}
.text-with-mentions__hashtags:hover {
  color: #ff3fbf;
}
.text-with-mentions__mentions {
  color: #18181A;
  cursor: pointer;
  font-weight: bold;
  text-transform: capitalize;
}
.text-with-mentions__mentions:hover {
  color: #ff3fbf;
}

.link--custom {
  color: #18181a;
  font-weight: 600;
  text-decoration: underline;
}
.link--custom:hover {
  color: #ff3fbf;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.message-item {
  font-family: "Roboto", sans-serif;
  border-bottom: 1px solid #DEE0E3;
  margin: 0 20px 0 30px;
}
.message-item:last-child {
  border-bottom: none;
}
.message-item__header {
  padding: 20px 0 10px 0;
}
.message-item__header--like {
  padding: 20px 0;
}
.message-item__avatar--left {
  width: 100%;
}
.message-item__avatar--right {
  width: 100%;
}
.message-item__avatar--right .avatar-media {
  float: right;
}
.message-item__avatar--right .avatar-media small {
  display: block;
  text-align: right;
}
.message-item__avatar--right .avatar-media__avatar {
  float: right;
  padding-right: 0;
  padding-left: 10px;
}
.message-item__author-avatar {
  cursor: pointer;
}
.message-item__author-name {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}
.message-item__text {
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #7C8089;
  overflow: hidden;
  white-space: pre-line;
}
.message-item__text--right {
  text-align: right;
}
.message-item__text--moderator-rights {
  padding: 0 0 16px 0;
}
.message-item__text--moderated {
  color: #B4B4BE;
}
.message-item__moderate-comments {
  font-size: 12px;
  text-align: right;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  padding: 0 0 10px 0;
  cursor: pointer;
}
.message-item__indicator {
  color: #C2C7CC;
  font-weight: bold;
  font-size: 12px;
}
.message-item__indicator--right {
  float: right;
  margin-top: 4px;
}
.message-item__indicator--left {
  color: #D2D2D2;
  float: left;
}
.message-item__image {
  cursor: pointer;
  width: 100%;
}
.message-item__image-container {
  margin-top: 20px;
}
.message-item .avatar-media .heading {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #18181A;
  margin: 0;
}
.message-item .avatar-media__body small {
  font-size: 14px;
  color: #A8A8A8;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.star-messages {
  position: relative;
}
.star-messages__accordion-header {
  min-height: 40px;
  width: 100%;
  padding: 10px 0;
  position: relative;
}
.star-messages__accordion-header::after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 20px;
  bottom: 0px;
  border-radius: 50%/20px;
  clip: rect(20px, auto, 50px, 0);
  opacity: 0.15;
  z-index: -1;
}
.star-messages__accordion-header--shadow::after {
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 10px;
}
.star-messages__accordion .suite-accordion__body {
  padding-top: 0;
}
.star-messages__accordion .suite-accordion__transition {
  overflow: inherit;
}
.star-messages__input {
  border: 1px solid #DEE0E3 !important;
  min-height: 30px !important;
  background: #FAFAFA !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 12px;
  padding: 0 50px 0 15px !important;
  border-radius: 4px;
}
.star-messages__input-icons {
  display: flex;
  position: absolute;
  z-index: 1;
  width: 45px;
  height: 21px;
  line-height: 18px;
  bottom: 16px;
  right: 77px;
  overflow: hidden;
  cursor: pointer;
}
.star-messages__input-icons__icon {
  width: 20px;
  height: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c2c7cc;
}
.star-messages__input-icons__icon:not(:last-child) {
  margin-right: 5px;
}
.star-messages__input-icon {
  background-color: #C2C7CC;
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px 0 0 5px;
  line-height: 30px !important;
  cursor: pointer;
  bottom: 12px;
  transition: 0.5s background-color;
}
.star-messages__input-icon.ui-component-icon_white:hover::before {
  -webkit-text-fill-color: #FFFFFF;
  fill: #FFFFFF;
}
.star-messages__input-icon--active {
  background-color: #000000;
}
.star-messages__input-wrapper {
  display: inline-block;
  min-height: 30px;
  padding-left: 20px;
  width: calc(100% - 70px);
}
.star-messages__input-wrapper .ui.input {
  height: 0;
}
.star-messages__input-wrapper .ui.icon.input > i.icon {
  top: 15px;
}
.star-messages__input-upload {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  outline: none;
  cursor: pointer;
}
.star-messages__input-upload::-webkit-file-upload-button {
  cursor: pointer;
}
.star-messages__input .public-DraftEditor-content {
  overflow-x: hidden;
}
.star-messages__input .public-DraftStyleDefault-block {
  white-space: pre-wrap;
  line-height: 28px;
  align-items: center;
}
.star-messages__input .public-DraftEditorPlaceholder-inner {
  line-height: 28px;
}
.star-messages__input .public-DraftEditorPlaceholder-hasFocus {
  line-height: 28px;
}
.star-messages__comments {
  background-color: #F6F7F9;
}
.star-messages--is-loading .star-messages__accordion-header {
  padding: 6px 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-congratulations-user {
  align-items: center;
  display: flex;
  padding: 1px 20px;
}
.smu-congratulations-user--is-commented {
  background-color: #F5F5F5;
  min-height: 72px;
}
.smu-congratulations-user--hide-interactions {
  min-height: 58px;
}
.smu-congratulations-user__messages {
  padding: 10px 0 10px 10px;
}
.smu-congratulations-user__messages-success {
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  color: #7C8089;
  padding-left: 20px;
}
.smu-congratulations-user__messages-strong {
  font-weight: bold;
  color: #18181A;
}
.smu-congratulations-user__messages-link {
  text-decoration: underline;
  color: #7C8089;
}
.smu-congratulations-user__messages-link:hover {
  color: #18181A;
  text-decoration: underline;
}
.smu-congratulations-user:not(.smu-congratulations-user--hide-interactions) > *:first-child {
  flex: none;
  width: 260px;
}
.smu-congratulations-user:not(.smu-congratulations-user--hide-interactions) > *:last-child {
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-congratulations-users__header {
  align-items: center;
  display: flex;
  padding: 20px;
}
.smu-congratulations-users__logo {
  margin-right: 20px;
}
.smu-congratulations-users__title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
}
.smu-congratulations-users__description {
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.29;
  margin-top: 5px;
}
.smu-congratulations-users__user {
  border-bottom: solid 1px #DEE0E3;
}
.smu-congratulations-users__user:last-of-type {
  border-bottom: none;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.header-users-avatars {
  align-items: center;
  background: linear-gradient(68deg, #cf21d6, #ff3fbf);
  display: flex;
  padding: 60px 30px;
}
.header-users-avatars__list {
  display: flex;
  flex-wrap: wrap;
  margin-right: 30px;
  width: 64px;
}
.header-users-avatars__list--multiple {
  width: 128px;
}
.header-users-avatars__list-item, .header-users-avatars__list-count {
  margin: 2px;
  position: relative;
  z-index: 1;
}
.header-users-avatars__list-item::before, .header-users-avatars__list-count::before {
  background-color: #18181a;
  border-radius: 100%;
  content: "";
  filter: blur(5px);
  height: 50px;
  left: 50%;
  margin-top: 15px;
  opacity: 0.5;
  position: absolute;
  transform: translateX(-50%);
  width: 50px;
  z-index: -1;
}
.header-users-avatars__list-avatar, .header-users-avatars__list-count {
  border: solid 1px #eaeaea;
}
.header-users-avatars__list-count {
  align-items: center;
  background-color: #18181a;
  border-radius: 100%;
  color: #fff;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  justify-content: center;
  line-height: 1.17;
  width: 60px;
}
.header-users-avatars__title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  line-height: 1.11;
  margin-bottom: 10px;
}
.header-users-avatars__description {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-icon-text {
  align-items: center;
  display: flex;
}
.smu-icon-text__icon {
  font-size: 28px;
  margin-right: 10px;
}
.smu-icon-text__icon::before {
  -webkit-text-fill-color: #707070;
  -webkit-text-stroke: #707070;
  color: #707070;
  fill: #707070;
  text-stroke: #707070;
}
.smu-icon-text__text {
  color: #7c8089;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.19;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.add-calendar-modal__close::before {
  -webkit-text-fill-color: #FFFFFF;
  -webkit-text-stroke: #FFFFFF;
  color: #FFFFFF;
  fill: #FFFFFF;
  text-stroke: #FFFFFF;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.chillout-modal {
  animation: slide-from-top 0.4s ease-in-out;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  transform: translateY(-100%);
}
.chillout-modal label {
  color: #7C8089;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 10px;
}
.chillout-modal__loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.chillout-modal__content {
  background: #FFFFFF;
  height: calc(100vh - 132px);
  overflow-y: auto;
  padding: 40px 0px;
}
.chillout-modal__content-wrapper {
  margin: 0 auto;
}
@media screen and (min-width: 900px) {
  .chillout-modal__content-wrapper {
    max-width: 700px;
    width: 60%;
  }
}
.chillout-modal__form-buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 79px;
}
.chillout-modal__form-cancel {
  margin-right: 20px;
}
.chillout-modal__form-date {
  width: 100%;
}
.chillout-modal__form-hour {
  flex: none;
  margin-left: 20px;
  width: 138px;
}
.chillout-modal__form-title, .chillout-modal__form-description {
  margin-bottom: 10px;
}
.chillout-modal__header {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
@media screen and (min-width: 900px) {
  .chillout-modal__header {
    max-width: 700px;
    width: 60%;
  }
}
.chillout-modal__header-close {
  border: none;
  height: 16px;
  line-height: 16px;
  min-height: 16px;
  padding: 0;
  position: absolute;
  right: 40px;
  top: 30px;
  width: 36px;
}
.chillout-modal__header-name {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}
.chillout-modal__header-wrapper {
  background: #FAFAFA;
  border: 1px solid #DEE0E3;
  margin: 0 auto;
  width: 100%;
}
.chillout-modal__title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  margin-bottom: 10px;
  margin-top: 40px;
  text-transform: uppercase;
}
.chillout-modal__when {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.chillout-modal__who {
  align-items: center;
  background: #F0F2F5;
  border: 1px solid #DEE0E3;
  display: flex;
  height: 79px;
  justify-content: center;
  width: 100%;
}
.users-list {
  display: flex;
  flex-wrap: wrap;
}
.users-list__user {
  padding: 10px;
  width: 50%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.discover-filters {
  display: flex;
  flex-direction: column;
}
.discover-filters__header {
  align-items: center;
  border-bottom: solid 1px #EAEAEA;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
.discover-filters__title {
  color: #c2c7cc;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0;
}
.discover-filters__loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.discover-filters__filters {
  flex: 1 1;
  overflow-y: auto;
  padding: 5px 10px 25px;
}
.discover-filters__chips {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.discover-filters__chip {
  align-items: center;
  background-color: #F0F2F5;
  border-radius: 24px;
  color: #7C8089;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.17;
  margin: 5px 5px 0 0;
  padding: 7px 15px;
}
.discover-filters__chip--disabled {
  opacity: 0.5;
}
.discover-filters__chip-close {
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  height: 15px;
  justify-content: center;
  margin-left: 3px;
  width: 15px;
}
.discover-filters__chip-close:hover {
  background-color: #d1d7e1;
}
.discover-filters__filter {
  margin-top: 15px;
}
.discover-filters__footer {
  border-top: solid 1px #EAEAEA;
  padding: 20px;
}
.discover-filters__submit {
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.discover-options {
  align-items: center;
  display: flex;
}
.discover-options__button {
  align-items: center;
  margin: 0 6px;
}
.discover-options__icon {
  margin-left: 4px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.toast-notifications-actions {
  color: #E00B84;
  cursor: pointer;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-right: -30px;
  position: relative;
  right: -10px;
  text-transform: uppercase;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.discover-users-user {
  align-items: center;
  border-bottom: solid 1px #EAEAEA;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
.discover-users-user > * {
  width: 40%;
}
.discover-users-user > *:last-child {
  width: 10%;
}
.discover-users-user__email {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.17;
}
.discover-users-user__checkbox {
  display: flex;
  justify-content: flex-end;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.discover-users-default {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px;
}
.discover-users-default__logo {
  height: 131px;
  margin-bottom: 28px;
}
.discover-users-default__texts {
  margin: 0 auto;
}
.discover-users-default__description {
  margin: 0;
  padding: 0;
  list-style: none;
}
.discover-users-default__title {
  color: #c2c7cc;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.discover-users-default__option {
  color: #7C8089;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.5;
}
.discover-users-default__option span {
  color: #f43dbf;
  font-weight: bold;
}
.discover-users-footer {
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid getThemeColor("gray.470");
  display: flex;
  padding: 10px 20px;
  position: relative;
}
.discover-users-footer::after {
  background: transparent linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(128, 128, 128, 0.1019607843) 100%) 0% 0% no-repeat padding-box;
  content: "";
  height: 23px;
  left: 0;
  position: absolute;
  top: -23px;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.discover-users-empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
}
.discover-users-empty__image {
  margin-bottom: 20px;
}
.discover-users-empty__title {
  color: #18181a;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.discover-users-empty__subtitle {
  color: #7c8089;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.43;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.discover-users-list {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}
.discover-users-list__header {
  align-items: center;
  border-bottom: solid 1px #EAEAEA;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.discover-users-list__count {
  color: #c2c7cc;
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
}
.discover-users-list__count span {
  color: #18181A;
  margin-left: 6px;
}
.discover-users-list__disclaimer {
  background: #CF21D6;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding: 15px 20px;
}
.discover-users-list__items {
  flex: 1 1;
  overflow-y: auto;
}
.discover-users-list__loader {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.discover-users {
  display: flex;
  flex-direction: column;
}
.discover-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 90px 0 20px 0;
}
.discover-layout > * {
  display: flex;
  height: 100%;
}
.discover-layout__loader {
  align-items: center;
  display: flex;
  width: 100%;
}
.discover-layout__filters {
  max-width: 295px;
  width: 100%;
}
.discover-layout__users {
  margin-left: 20px;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.recognition-expanded__icon {
  cursor: pointer;
}
.recognition-expanded__paginator {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
}
.recognition-expanded__paginator .datatable__page {
  padding: 7px;
  cursor: pointer;
}
.recognition-expanded__paginator .datatable__page--selected {
  font-weight: bold;
  color: #18181A;
}
.recognition-expanded__paginator .datatable__page--disabled {
  color: #DEE0E3;
  cursor: not-allowed;
}
.recognition-expanded__user {
  width: 50%;
  padding: 10px;
}
.recognition-expanded__users-wrapper {
  align-content: start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden scroll;
  padding: 10px;
  width: 100%;
}
.recognition-expanded__title {
  text-transform: uppercase;
}
.timeline-hashtags {
  position: relative;
  width: 100%;
}
.timeline-hashtags__header {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 10;
}
.timeline-hashtags__container {
  display: flex;
  justify-content: center;
  margin-top: 130px;
  padding: 20px 0 40px 0;
  width: 100%;
}
.timeline-hashtags__feed {
  max-width: 610px;
  width: 610px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.chillout-chip {
  background-color: #CF21D6;
  border-radius: 24px;
  border: solid 1px transparent;
  color: #FFFFFF;
  display: inline-flex;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  min-height: 30px;
  padding: 8px 16px;
  text-align: left;
  -webkit-user-select: none;
          user-select: none;
}
.chillout-chip--outlined {
  background-color: transparent;
  border: solid 1px #DEE0E3;
  color: #7C8089;
}
.chillout-chip--clickable, .chillout-chip--clickable > * {
  cursor: pointer;
}
.chillout-chip--disabled {
  cursor: default;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.suite-widget-chillout-config {
  display: flex;
}
.suite-widget-chillout-config__section:first-child {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 40%;
}
.suite-widget-chillout-config__section:last-child {
  overflow-y: auto;
  padding: 30px 50px 14px 20px;
  width: 60%;
}
@media (max-width: 991px) {
  .suite-widget-chillout-config__section:last-child {
    padding-left: 47px;
  }
}
.suite-widget-chillout-config__image {
  max-width: 300px;
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1279px) {
  .suite-widget-chillout-config__image {
    max-width: 260px;
  }
}
@media (max-width: 991px) {
  .suite-widget-chillout-config__image {
    max-width: 227px;
  }
}
.suite-widget-chillout-config__options-title {
  color: #18181A;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 15px;
  margin-top: 10px;
}
.suite-widget-chillout-config__options-warning {
  margin-top: 10px;
}
.suite-widget-chillout-config__options-warning-icon {
  font-size: 14px;
  margin-right: 6px;
}
.suite-widget-chillout-config__options-warning-icon::before {
  -webkit-text-fill-color: #0277a8;
  -webkit-text-stroke: #0277a8;
  color: #0277a8;
  fill: #0277a8;
  text-stroke: #0277a8;
}
.suite-widget-chillout-config__options-warning-text {
  color: #0277a8;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.14;
}
.suite-widget-chillout-config__options-chip {
  margin-bottom: 10px;
  margin-right: 10px;
}
.suite-widget-chillout-config__options-chip:last-child {
  margin-right: 0;
}
.suite-widget-chillout-config__options-content--time {
  align-items: center;
  display: flex;
  padding-bottom: 10px;
}
.suite-widget-chillout-config__options:first-child .suite-widget-chillout-config__options-title {
  margin-top: 0;
}
.suite-widget-chillout-config__select-time {
  display: flex;
  flex-direction: column;
}
.suite-widget-chillout-config__select-time label {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: left;
}
.suite-widget-chillout-config__select-time select {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: solid 1px #DEE0E3;
  height: auto;
  margin-bottom: 0;
  min-width: 80px;
  padding: 8px 10px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
body .modals.dimmer .ui.scrolling.modal.suite-widget-chillout-modal-wrapper {
  box-shadow: none !important;
  margin: 0 auto !important;
  transform: none;
}
body .modals.dimmer .ui.modal.suite-widget-chillout-modal-wrapper {
  box-shadow: none !important;
  margin: 0 auto !important;
}

.suite-widget-chillout-modal {
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  max-height: 90vh;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.suite-widget-chillout-modal-wrapper {
  padding: 0 45px;
  max-height: 90vh;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .suite-widget-chillout-modal-wrapper {
    padding: 0 10px;
  }
}
.suite-widget-chillout-modal__loading {
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.suite-widget-chillout-modal__header {
  background-image: linear-gradient(to right, #CF21D6, #FF3FBF);
  display: flex;
  flex-direction: column;
  min-height: 189px;
  justify-content: center;
  padding: 30px;
  position: relative;
}
.suite-widget-chillout-modal__title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.11;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .suite-widget-chillout-modal__title {
    font-size: 36px;
  }
}
.suite-widget-chillout-modal__subtitle, .suite-widget-chillout-modal__text {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}
.suite-widget-chillout-modal__subtitle {
  font-weight: bold;
}
.suite-widget-chillout-modal__text {
  font-size: 14px;
}
.suite-widget-chillout-modal__icon-close {
  cursor: pointer;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 21px;
  top: 25px;
}
.suite-widget-chillout-modal__icon-close::before {
  -webkit-text-fill-color: #FFFFFF;
  -webkit-text-stroke: #FFFFFF;
  color: #FFFFFF;
  fill: #FFFFFF;
  text-stroke: #FFFFFF;
}
.suite-widget-chillout-modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  position: relative;
}
.suite-widget-chillout-modal__divider.ui-components.suite-divider {
  margin-top: 0;
}
.suite-widget-chillout-modal__footer {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: flex-end;
  padding: 0 20px;
}
.suite-widget-chillout-modal--loading .suite-widget-chillout-modal__config {
  opacity: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.chill-out-event {
  display: flex;
}
@media (max-width: 991px) {
  .chill-out-event--with-top-padding {
    padding-top: 23px;
  }
}
@media (max-width: 991px) {
  .chill-out-event {
    display: block;
  }
}
.chill-out-event__date {
  align-items: center;
  border-right: 1px solid #DEE0E3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
  padding-right: 15px;
  width: 50px;
}
@media (max-width: 991px) {
  .chill-out-event__date {
    align-items: normal;
    border-right: none;
    flex-direction: row;
    justify-content: start;
    margin: 0 0 4px 0;
    padding-right: 0;
    width: auto;
  }
}
.chill-out-event__date-day {
  font-size: 30px;
  margin: 5px 0 0 0;
}
@media (max-width: 991px) {
  .chill-out-event__date-day {
    font-size: 12px;
    font-weight: normal;
    margin: 0 0 5px 0;
  }
}
.chill-out-event__date-month {
  font-size: 14px;
  line-height: 1.17;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  .chill-out-event__date-month {
    font-size: 12px;
    padding-right: 5px;
  }
}
.chill-out-event__details {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  flex: 4 1;
  justify-content: center;
  min-height: 118px;
}
@media (max-width: 991px) {
  .chill-out-event__details {
    min-height: auto;
  }
}
.chill-out-event__details-status.ui.small.label {
  background-color: #ceeecc;
  color: #099401;
  font-size: 8px;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 3px 5px;
}
.chill-out-event__details-status.ui.small.label.chill-out-event__details-status--coming-soon {
  background-color: #d9d9f8;
  color: #4B4EFF;
}
@media (max-width: 991px) {
  .chill-out-event__details-status.ui.small.label {
    position: absolute;
    top: 15px;
  }
}
.chill-out-event__details-title, .chill-out-event__details-subtitle {
  margin-bottom: 5px;
}
.chill-out-event__details-title {
  font-weight: bold;
  line-height: 1.14;
}
.chill-out-event__details-subtitle {
  display: flex;
  flex-direction: column;
}
.chill-out-event__details-subtitle-text, .chill-out-event__details-subtitle-time {
  color: #7C8089;
  font-size: 12px;
  line-height: 1.33;
}
.chill-out-event__details-join-btn {
  margin: 0 0 3px 0;
}
.chill-out-event__details-join-btn-link, .chill-out-event__details-join-btn-link:hover {
  color: #FFFFFF;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.chill-out-events__header {
  align-items: center;
  background-image: linear-gradient(to right, #CF21D6 0%, #FF3FBF 100%);
  border: solid 1px #dee0e3;
  display: flex;
  height: 65px;
  justify-content: space-between;
  padding: 20px;
}
@media (max-width: 991px) {
  .chill-out-events__header {
    padding: 20px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .chill-out-events__header {
    padding: 20px 10px;
  }
}
@media (max-width: 767px) {
  .chill-out-events__header {
    padding: 20px 7px;
  }
}
.chill-out-events__header-icon {
  cursor: pointer;
  font-size: 20px;
}
@media (max-width: 991px) {
  .chill-out-events__header-img {
    width: 27px;
    height: 29.5px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.chill-out-events__segment .suite-segment__spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 138px;
}
.chill-out-events__body {
  align-content: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}
.chill-out-events__loader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.chill-out-events__carousel--redirecting {
  opacity: 0;
}
.chill-out-events .suite-carousel {
  padding: 0;
  width: calc(100% - 50px);
}
.chill-out-events .suite-carousel * {
  -webkit-user-select: none;
          user-select: none;
}
.chill-out-events.suite-panel h2 {
  color: #FFFFFF;
  margin: 0;
  padding: 0 10px;
}
@media (max-width: 991px) {
  .chill-out-events.suite-panel h2 {
    font-size: 14px;
    margin: 0 12px;
  }
}
@media (max-width: 767px) {
  .chill-out-events.suite-panel h2 {
    margin: 0 6px;
    padding: 0;
  }
}
.chill-out-events__direction--prev {
  right: 90%;
}
.chill-out-events__direction--next {
  left: 90%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.suite-widget-chillout-error {
  align-content: center;
  align-items: self-end;
  background-image: url(/static/media/empty_state.5e827b44.svg);
  background-origin: content-box;
  background-position: right center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 182px;
}
@media (max-width: 991px) {
  .suite-widget-chillout-error {
    background-image: url(/static/media/empty_state_768.72cb3f12.svg);
    background-origin: border-box;
    background-position: center 20px;
    min-height: 260px;
    padding-top: 120px;
  }
}
.suite-widget-chillout-error.suite-panel h2 {
  margin-bottom: 8px;
  max-width: 95px;
  width: 100%;
}
@media (max-width: 991px) {
  .suite-widget-chillout-error.suite-panel h2 {
    width: auto;
  }
}
.suite-widget-chillout-error .panel__text {
  color: #7C8089;
  width: 55%;
}
@media (max-width: 991px) {
  .suite-widget-chillout-error .panel__text {
    width: auto;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.suite-widget-chillout {
  background-position: top right;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .suite-widget-chillout {
    padding-top: 103px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.suite-widget-chillout__segment .suite-segment__spinner {
  align-items: center;
  display: flex;
  height: 91px;
  justify-content: center;
}
.suite-widget-chillout__button {
  height: 20px;
  line-height: 20px;
}
@media (max-width: 991px) {
  .suite-widget-chillout__button {
    font-size: 10px;
    line-height: 1.3;
    padding: 3px 8px;
  }
}
@media (min-width: 992px) {
  .suite-widget-chillout__button {
    font-size: 10px;
    padding: 0 13px;
  }
}
@media (min-width: 1280px) {
  .suite-widget-chillout__button {
    height: 20px;
    line-height: 20px;
  }
}
.suite-widget-chillout.suite-panel h2 {
  margin-bottom: 5px;
  width: 40%;
}
@media (max-width: 991px) {
  .suite-widget-chillout.suite-panel h2 {
    width: 100%;
  }
}
.suite-widget-chillout .panel__text {
  color: #7C8089;
  width: 56%;
}
@media (max-width: 991px) {
  .suite-widget-chillout .panel__text {
    width: 100%;
  }
}
.suite-widget-chillout--loading .panel__text {
  display: none;
}
.suite-widget-chillout--default, .suite-widget-chillout--empty {
  background-image: url(/static/media/chill_background.d37d762d.svg);
}
@media (max-width: 991px) {
  .suite-widget-chillout--default, .suite-widget-chillout--empty {
    background-origin: border-box;
    background-image: url(/static/media/chill_background_768.36ddd5de.svg);
    background-size: auto 100px;
  }
}
.skeleton-overall-activity__description {
  margin-bottom: 7px;
}
.skeleton-overall-activity__line {
  position: relative;
}
.skeleton-overall-activity__title {
  margin: 0 0 20px;
}
.skeleton-overall-activity__stat {
  position: absolute;
  right: 0;
  top: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.community-counters {
  width: 100%;
  font-size: 16px;
}
.community-counters__line {
  position: relative;
}
.community-counters__line:last-child p {
  margin-bottom: 0;
}
.community-counters__description {
  font-weight: bold;
  margin-bottom: 7px;
}
.community-counters__stat {
  position: absolute;
  right: 0;
  top: 0;
  color: #7C8089;
}
.coreValue__icon {
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
  flex-shrink: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.core-value__button {
  border-radius: 5px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 700;
  height: 54px;
  margin: 8px 3px;
  min-width: 180px;
  outline: 0;
  overflow: hidden;
  padding: 0 10px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  -webkit-user-select: none;
          user-select: none;
  width: 180px;
}
.core-value__button:before {
  background: inherit;
  content: "";
  filter: brightness(0.94);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
.core-value__button-text {
  max-height: 52px;
  overflow-x: visible;
  position: relative;
  word-break: break-word;
  z-index: 1;
}
.core-value__button-img {
  background: inherit;
  border-radius: 50%;
  margin: 0 8px 0 0;
  position: relative;
  z-index: 1;
}
.core-value__button-description {
  white-space: pre-line;
}
.core-value__button-wrapper {
  align-items: center;
  display: flex;
  justify-content: left;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
}
.core-value__disabled-layer {
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.core-value__popup.ui.popup {
  animation: fadeIn 0.2s ease-in;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  background: #18181A;
  border: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 20px;
  white-space: pre-line;
  width: 230px;
}
.core-value__popup.ui.popup.left.center.popup:before, .core-value__popup.ui.popup.right.center.popup:before {
  background: #18181A;
}
.core-value__popup.ui.popup:before {
  background: #18181A;
  box-shadow: none !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.core-values-panel {
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 70px;
  justify-content: center;
  margin-top: 64px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.core-values-panel--with-controls {
  justify-content: flex-start;
}
.core-values-panel--with-controls .core-values-panel__values {
  padding: 0 60px;
}
.core-values-panel__control {
  align-items: center;
  background: #FFFFFF;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 70px;
  justify-content: center;
  padding: 0 16px;
  position: absolute;
  width: 60px;
  z-index: 2;
}
.core-values-panel__control--left {
  left: 0;
}
.core-values-panel__control--right {
  right: 0;
}
.core-values-panel__values {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.survey__badge {
  align-items: center;
  border-radius: 50%;
  color: #FFFFFF;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  justify-content: center;
  margin-right: 10px;
  width: 40px;
}
.survey__date {
  color: #C2C7CC;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
}
.survey__title {
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  overflow-wrap: break-word;
}
.survey__dismiss {
  align-items: center;
  display: flex;
  flex: none;
  justify-content: center;
  margin-left: 5px;
}
.survey__dismiss--icon {
  cursor: pointer;
}
.survey__dismiss--icon::before {
  -webkit-text-fill-color: #7C8089;
  -webkit-text-stroke: #7C8089;
  color: #7C8089;
  fill: #7C8089;
  text-stroke: #7C8089;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.surveys-widget {
  padding-bottom: 0;
}
.surveys-widget__red-dot {
  background-color: #D5152E;
  border-radius: 50%;
  height: 5px;
  margin-left: 5px;
  position: relative;
  top: -5px;
  width: 5px;
}
.surveys-widget__empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 180px;
  justify-content: center;
  width: 100%;
}
.surveys-widget__empty-text {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  margin-top: 5px;
}
.surveys-widget__image {
  position: absolute;
  right: 15px;
  top: 20px;
}
.surveys-widget__surveys {
  height: 180px;
  overflow: hidden auto;
}
.surveys-widget__surveys > div {
  height: 100%;
}
.surveys-widget__tab {
  align-items: center;
  display: flex;
  font-size: 12px;
  height: 30px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.skeleton-top-star {
  align-items: center;
  display: flex;
  padding: 10px 0;
}
.skeleton-top-star__avatar-media {
  margin-right: 5px;
}
.skeleton-top-star__avatar-name {
  margin-bottom: 5px;
}
.skeleton-top-star__avatar-text-wrapper {
  flex: 1 1;
}
.upload-profile-user-modal--cropper .smu-generic-modal__body {
  min-height: 300px;
  padding: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.home-layout {
  padding: 20px 0 0 0 !important;
}
@media only screen and (max-width: 576px) {
  .home-layout {
    padding: 80px 0 0 0;
  }
}
.home-layout__grid {
  display: flex;
  flex-flow: row wrap;
}

.home-right-panel {
  min-height: 510px;
}
@media only screen and (max-height: 650px) {
  .home-right-panel {
    overflow-y: scroll;
    height: 400px;
    min-height: 400px;
  }
}

.ui.small.modal {
  left: 0;
  right: 0;
  margin: 0 auto !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.ui-components.suite-grid {
  display: flex;
}

.ui-components.suite-column {
  padding: 0 10px;
}

.ui-components.suite-column-desktop-size-3 {
  width: 25%;
}

.ui-components.suite-column-desktop-size-4 {
  width: 33.333%;
}

.ui-components.suite-column-desktop-size-6 {
  width: 50%;
}

.ui-components.suite-column-desktop-size-8 {
  width: 66.666%;
}

@media only screen and (max-width: 992px) {
  .ui-components.suite-column-desktop-sm-size-4 {
    width: 30%;
  }
}

@media only screen and (max-width: 992px) {
  .ui-components.suite-column-desktop-sm-size-8 {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .ui-components.suite-column-tablet-size-12 {
    width: 100%;
  }
}

.show-from-desktop-sm {
  display: none;
}
@media only screen and (max-width: 992px) {
  .show-from-desktop-sm {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .hide-from-desktop-sm {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hide-from-tablet {
    display: none;
  }
}

.show-from-tablet {
  display: none;
}
@media only screen and (max-width: 768px) {
  .show-from-tablet {
    display: block;
  }
}

.show-from-mobile {
  display: none;
}
@media only screen and (max-width: 576px) {
  .show-from-mobile {
    display: block;
  }
}

.hide-from-mobile {
  display: block;
}
@media only screen and (max-width: 576px) {
  .hide-from-mobile {
    display: none;
  }
}

.app-components_toast-notifications--black .app-components_toast-notifications__list {
  overflow-y: visible;
}
.insight-details-layout {
  padding: 80px 0 0 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights__feed {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  width: calc(100% + 15px);
}
.insights__card, .insights__skeleton-card {
  width: 33%;
  padding: 7.5px;
}
.insights__actions {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.insights__load-more {
  background-color: #26277e;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px 20px;
  text-transform: capitalize;
  width: 175px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .insights__card, .insights__skeleton-card {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .insights__card, .insights__skeleton-card {
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-card {
  border-radius: 8px;
  box-shadow: 0 1px 2.5px rgba(24, 24, 26, 0.1);
  display: flex;
  flex-direction: column;
  font-family: "Rubik", sans-serif;
  margin: 0;
  min-height: 240px;
  padding: 15px;
  width: 100%;
}
.insights-card:hover {
  box-shadow: 0 2px 5px rgba(24, 24, 26, 0.1);
  transition: 0.2s;
}
.insights-card__header {
  align-items: center;
  display: flex;
  font-size: 12px;
}
.insights-card__info {
  background-color: #d9d9f8;
  color: #4b4eff;
  margin-left: auto;
}
.insights-card__title {
  color: #14145a;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  min-height: 40px;
}
.insights-card__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
}
.insights-card__content-description {
  color: #7C8089;
  font-size: 14px;
  height: 90px;
  line-height: 1.71;
  text-align: left;
}
.insights-card__content-metric {
  color: #412296;
  font-size: 48px;
  font-weight: bold;
  height: 60px;
  line-height: 0.83;
  margin: 0 20px 0 0;
  text-align: left;
}
.insights-card__content-cultural {
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 20px 0;
}
.insights-card__content-cultural-indicator {
  color: #412296;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
}
.insights-card__content-cultural-description {
  color: #7C8089;
  font-size: 14px;
  height: 90px;
  line-height: 1.71;
  margin-top: 10px;
  text-align: left;
}
.insights-card__action {
  background-color: #26277e;
  border-radius: 8px;
  bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  position: absolute;
  right: 20px;
  text-transform: capitalize;
  width: 175px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-card-organization-value {
  align-items: center;
  display: flex;
  width: 100%;
}
.insights-card-organization-value__image {
  margin-right: 20px;
  width: 75px;
}
.insights-card-organization-value__name {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  max-width: 60%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.insights-empty-state {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  margin: 0 auto;
  align-items: center;
  min-height: 175px;
  padding: 15px;
}
.insights-empty-state__title {
  margin-top: 20px;
  color: #14145a;
}
.insights-empty-state__description {
  color: #7c8089;
  font-size: 13px;
}
.insights-empty-state__button {
  background-color: #14145a;
  margin-top: 20px;
  padding: 10px 30px;
}
.insights-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 80px 0 34px 0;
}
.insights-layout__loader {
  align-items: center;
  display: flex;
  height: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.skeleton-leaderboard__item {
  align-items: center;
  display: flex;
  height: 80px;
  padding: 0px 15px;
  width: 100%;
}
.skeleton-leaderboard__item:not(:last-child) {
  border-bottom: 1px solid #EAEAEA;
}
.skeleton-leaderboard__item-position {
  display: flex;
  justify-content: center;
  width: 60px;
}
.skeleton-leaderboard__item-user {
  flex: 1 1;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.leaderboard__filter-tabs {
  display: flex;
}
.leaderboard__filter-button {
  border-color: transparent;
  flex: none;
  margin-left: 5px;
  padding: 0;
  width: 50px;
}
.leaderboard__filters-wrapper {
  padding: 10px 20px 20px;
  display: flex;
  flex-wrap: wrap;
}
.leaderboard__filter-input-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: calc(33.333% - 10px);
}
.leaderboard__filter-input {
  border: 1px solid #DEE0E3;
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 30px;
  outline: none;
  padding: 0px 10px;
  width: 100%;
}
.leaderboard__filter-input-label {
  color: #B4B4BE;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.leaderboard__filter-select-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: calc(33.333% - 10px);
}
.leaderboard__filter-select {
  background: transparent;
  background-image: linear-gradient(45deg, transparent 50%, #7C8089 50%), linear-gradient(135deg, #7C8089 50%, transparent 50%);
  background-position: calc(100% - 20px) 12px, calc(100% - 15px) 12px, calc(100% - 35px) 10px;
  background-size: 5px 5px, 5px 5px, 1px 21px;
  background-repeat: no-repeat;
  border: 1px solid #DEE0E3;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 30px;
  outline: none;
  padding: 0px 35px 0px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.leaderboard__filter-select__option-selected {
  color: #18181A;
}
.leaderboard__filter-select::-ms-expand {
  display: none;
}
.leaderboard__filter-select-label {
  color: #B4B4BE;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.leaderboard-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.leaderboard-list__item {
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  display: flex;
  height: 78px;
  padding: 0 30px 0 60px;
  position: relative;
}
.leaderboard-list__item:last-child {
  border-bottom: none;
}
.leaderboard-list__item:nth-child(n+4) .ui-component-icon_yellow::before {
  fill: #DEE0E3;
  text-stroke: #DEE0E3;
  -webkit-text-fill-color: #DEE0E3;
  -webkit-text-stroke: #DEE0E3;
}
.leaderboard-list__item--highlighted {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid #EAEAEA;
  z-index: 1;
}
.leaderboard-list__item--highlighted .leaderboard-list__item-position {
  border-bottom: 1px solid #EAEAEA;
  height: 77px;
}
.leaderboard-list__item--highlighted .leaderboard-list__item-position::before {
  background: #dfdfdf;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 100%;
}
.leaderboard-list__item .top-star-item {
  width: calc(100% - 15px);
}
.leaderboard-list__item-position {
  background: #FFFFFF;
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 78px;
  left: 0;
  line-height: 78px;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 60px;
  z-index: 0;
}

.ui-components .suite-filters__header {
  display: flex;
}
.ui-components .suite-filters__header-text {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  line-height: 50px;
  margin: 0 0 0 20px;
  text-transform: uppercase;
  width: 100%;
}
.ui-components .suite-filters__filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
}
.ui-components .suite-filters__filter-input {
  border: 1px solid #DEE0E3;
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 50px;
  outline: none;
  padding: 0px 10px;
  width: calc(100% - 10px);
}
.ui-components .suite-filters__filter-input-wrapper {
  margin: 0px 10px 10px 0px;
  position: relative;
  width: calc(33.333% - 10px);
}
.ui-components .suite-filters__filter-select {
  background: transparent;
  background-image: linear-gradient(45deg, transparent 50%, #7C8089 50%), linear-gradient(135deg, #7C8089 50%, transparent 50%);
  background-position: calc(100% - 20px) 24px, calc(100% - 15px) 24px, calc(100% - 35px) 10px;
  background-size: 5px 5px, 5px 5px, 1px 21px;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 50px;
  margin: 0;
  outline: none;
  padding: 0px 35px 0px 0px;
  width: 33.333%;
}
.ui-components .suite-filters__filter-select__option-selected {
  color: #18181A;
}
.ui-components .suite-filters__filter-select::-ms-expand {
  display: none;
}
.ui-components .suite-filters__select {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  background: transparent;
  width: 33.333%;
  border: none;
  outline: none;
  padding: 0 35px 0 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  height: 50px;
  background-image: linear-gradient(45deg, transparent 50%, #7c8089 50%), linear-gradient(135deg, #7c8089 50%, transparent 50%);
  background-position: calc(100% - 20px) 24px, calc(100% - 15px) 24px, calc(100% - 35px) 10px;
  background-size: 5px 5px, 5px 5px, 1px 21px;
  background-repeat: no-repeat;
}
.ui-components .suite-filters__select__option-selected {
  color: #18181A;
}
.ui-components .suite-filters__select::-ms-expand {
  display: none;
}
.ui-components .suite-filters__select-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}
.ui-components .suite-filters__button {
  background: transparent;
  border-radius: 0;
  border-left: 1px solid #DEE0E3;
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  height: 50px;
  text-transform: uppercase;
}
.ui-components .suite-filters__button:hover .ui-components .suite-filters__button:active, .ui-components .suite-filters__button:focus {
  background: #FAFAFA;
}
.ui-components .suite-filters__button:active, .ui-components .suite-filters__button:focus {
  background: transparent;
}
.ui-components .suite-filters__button--cta {
  background: #FAFAFA;
  color: #18181A;
  font-weight: bold;
}
.leaderboard-layout {
  padding: 80px 0 40px 0 !important;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.new-features__stroboscope {
  box-sizing: border-box;
  display: flex;
  font-weight: bold;
  height: 100px;
  margin-bottom: 20px;
  max-width: 500px;
  position: relative;
  padding: 50px 0 0 0;
  text-transform: uppercase;
}
.new-features__stroboscope-front {
  color: #001CEF;
  font-family: "Montserrat", sans-serif;
  font-size: 128px;
  position: relative;
  z-index: 1;
}
.new-features__stroboscope-middle {
  color: #FF3FBF;
  font-family: "Montserrat", sans-serif;
  font-size: 130px;
  left: 2px;
  position: absolute;
  top: 46px;
  z-index: -2;
}
.new-features__stroboscope-back {
  color: #00D8FF;
  font-family: "Montserrat", sans-serif;
  font-size: 130px;
  left: -10px;
  position: absolute;
  top: 52px;
  z-index: -1;
}
.new-features__feature {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
}
.new-features__feature--odd .new-features__feature-image-wrapper {
  order: 2;
}
.new-features__feature-date {
  color: #C2C7CC;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  text-transform: capitalize;
  top: 15px;
}
.new-features__feature-content {
  box-sizing: border-box;
  order: 1;
  padding: 0 20px;
  width: 60%;
}
.new-features__feature-content-button {
  background: #C81DD9;
}
.new-features__feature-content-button:hover {
  background: #9A00F0;
}
.new-features__feature-content-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
}
.new-features__feature-content-description {
  color: #7C8089;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7;
  margin-bottom: 20px 0;
  text-align: left;
  white-space: pre-wrap;
}
.new-features__feature-content-title {
  color: #151758;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin: 10px 0 20px;
  text-align: left;
}
.new-features__feature-image {
  width: 100%;
}
.new-features__feature-image-wrapper {
  box-sizing: border-box;
  margin: 0 20px;
  max-width: 370px;
  width: 30%;
}
.new-features__title {
  background: #FFCA4F;
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 11.7px;
  line-height: 27px;
  text-align: center;
}
.new-features__title-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.new-features-layout {
  box-sizing: border-box;
  padding: 110px 0 70px 0 !important;
  position: relative;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.notificationsHistory__text-decoration {
  color: rgba(0, 0, 0, 0.04);
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-weight: bold;
  height: 58px;
  line-height: 58px;
  position: relative;
  text-align: end;
  text-transform: uppercase;
  -webkit-user-select: none;
          user-select: none;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .notificationsHistory__text-decoration {
    font-size: 10vw;
  }
}
.notificationsHistory__info {
  border-bottom: solid 1px #EAEAEA;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.notificationsHistory__title {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #B4B4BE;
  margin-bottom: 5px;
}
.notificationsHistory__list {
  background-color: #FFFFFF;
  border-radius: 3px;
  border: solid 1px #DEE0E3;
  margin-top: -15px;
  max-width: 610px;
  position: relative;
  z-index: 2;
}
.notificationsHistory__item {
  border-bottom: solid 1px #EAEAEA;
  cursor: pointer;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  justify-content: space-between;
  min-height: 60px;
  padding: 16px 20px;
}
.notificationsHistory__item:last-child {
  border-bottom: none;
}
.notificationsHistory__item-user {
  align-items: center;
  display: flex;
}
.notificationsHistory__item--avatar {
  margin: auto;
}
.notificationsHistory__item--name {
  font-weight: bold;
}
.notificationsHistory__item--notification {
  color: #7C8089;
  font-size: 14px;
  font-weight: 400;
}
.notificationsHistory__item--text {
  padding-left: 20px;
  width: 470px;
  margin: auto;
  line-height: 16px;
}
.notificationsHistory__item--description {
  color: #7C8089;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  margin-top: 5px;
}
.notificationsHistory__item--date {
  margin: auto 0;
  min-width: 95px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notificationsHistory__item--date-text {
  color: #7C8089;
  font-size: 10px;
  padding-right: 5px;
}
.notificationsHistory__item--icon {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-left: -5px;
}
.notificationsHistory__item--image {
  width: 55%;
}
.notificationsHistory__item--active {
  background: #EEEEEE;
  border-bottom: 1px solid #FFFFFF;
}
.notificationsHistory-layout {
  padding: 78px 0 40px 0 !important;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.highlighted-star {
  display: flex;
  flex-direction: column;
}
.highlighted-star__title {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.badge-item {
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  height: 180px;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.badge-item--modal {
  flex-basis: 150px;
  margin-bottom: 10px;
  margin-right: 20px;
  padding: 15px;
}
.badge-item--modal:nth-of-type(4n) {
  margin-right: 0;
}
.badge-item__image {
  margin-left: 0;
}
.badge-item__percentage {
  background: #18181A;
  border-radius: 5px;
  height: 150px;
  opacity: 0.99;
  position: absolute;
  top: 100%;
  transition: all 0.3s ease-out;
  width: 100%;
}
.badge-item__percentage-container {
  padding: 15px;
}
.badge-item__percentage-number {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 10px 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
}
.badge-item__percentage-description {
  color: #C2C7CC;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-align: left;
}
.badge-item__percentage-description--line {
  margin: 0 0 10px 0;
}
.badge-item__percentage-medias {
  bottom: -12px;
  position: absolute;
  text-align: center;
  top: 79%;
  width: 100%;
}
.badge-item__percentage-medias .button--media {
  margin-right: 4px;
}
.badge-item__percentage-medias .button--media:last-child {
  margin-right: 0;
}
.badge-item:hover > .badge-item__percentage {
  top: 0;
  height: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.feed-profile__spinner--fetching {
  min-height: calc(100vh - 150px);
}

.panel-badges {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 35px;
}
.panel-badges__container {
  width: 100%;
  max-width: 33.333%;
}
@media only screen and (max-width: 576px) {
  .panel-badges__container {
    max-width: 50%;
  }
}
.paginated-feed {
  margin: 0 auto;
  max-width: 610px;
  width: 100%;
}
.paginated-feed .feed-profile__spinner--fetching {
  min-height: unset;
}
.unified-profile {
  padding-top: 84px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-type-emoji {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: background-color 0.1s linear;
  -webkit-user-select: none;
          user-select: none;
}
.questionnaires-flex-type-emoji:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.questionnaires-flex-type-emoji:hover .questionnaires-flex-type-emoji__item {
  transform: scale(1.05);
}
.questionnaires-flex-type-emoji__item {
  display: block;
  font-size: 30px;
  padding: 10px;
  transition: transform 0.1s linear;
}
.questionnaires-flex-type-emoji--active {
  background-color: rgba(255, 255, 255, 0.2);
}
.questionnaires-flex-type-emoji--active .questionnaires-flex-type-emoji__item {
  transform: scale(1.05);
}
.questionnaires-flex-type-emoji--disabled {
  opacity: 0.5;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-type-image {
  background-color: #FAFAFA;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: transform 0.1s linear;
  width: 100%;
}
.questionnaires-flex-type-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.questionnaires-flex-type-image:hover {
  transform: scale(1.05);
}
.questionnaires-flex-type-image--active {
  border: 2px solid #FFFFFF;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.36);
  transform: scale(1.05);
}
.questionnaires-flex-type-image--disabled {
  opacity: 0.5;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-type-text {
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 14px;
  padding: 15px 30px;
  position: relative;
  transition: background-color 0.1s linear;
  -webkit-user-select: none;
          user-select: none;
}
.questionnaires-flex-type-text:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.questionnaires-flex-type-text--active {
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: bold;
}
.questionnaires-flex-type-text--disabled {
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.5;
}
.questionnaires-flex-type-text__checkbox {
  position: absolute;
  right: 30px;
  top: 15px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.suggested-users-list {
  width: 100%;
}
.suggested-users-list__header {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.suggested-users-list__list {
  padding-top: 20px;
}
.suggested-users-list__list-item {
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.1s linear;
}
.suggested-users-list__list-item:hover {
  background-color: #f7f7fd;
}
.suggested-users-list__avatar-media-skeleton {
  align-items: center;
  display: flex;
  padding: 10px;
  width: 100%;
}
.suggested-users-list__avatar-media-skeleton-avatar {
  margin-right: 10px;
}
.suggested-users-list__avatar-media-skeleton-info {
  flex-grow: 1;
}
.suggested-users-list__avatar-media-skeleton-name {
  margin-bottom: 5px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.user-search {
  display: flex;
  flex-direction: column;
}
.user-search__input-wrapper {
  position: relative;
}
.user-search__input .suite-input-text__input {
  border-radius: 0;
  padding: 16px 30px;
}
.user-search__input-icon {
  position: absolute;
  right: 30px;
  top: 17px;
}
.user-search__list {
  background-color: #FFFFFF;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px 0;
}
.user-search__list .suggested-users-list__header {
  padding: 0 30px;
}
.user-search__list .suggested-users-list__list {
  padding: 20px 20px 0 20px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-type-send-star {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.questionnaires-flex-type-send-star__user-search {
  height: 100%;
  overflow: hidden;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-type-textfield .suite-input-area__wrapper {
  border-radius: 5px;
  border: 0px;
}
.questionnaires-flex-type-textfield .suite-input-area__counter {
  margin: 0;
  padding: 10px 16px 20px 0;
}
.questionnaires-flex-type-textfield textarea {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 16px;
  min-height: 340px;
  padding: 20px 16px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-type-nps {
  position: relative;
}
.questionnaires-flex-type-nps__content {
  background-color: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(240, 242, 245, 0.2);
  border-radius: 50%;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 14px;
  padding: 12px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
}
.questionnaires-flex-type-nps__content:hover {
  opacity: 1;
}
.questionnaires-flex-type-nps__content:first-child {
  border-top: 1px solid rgba(240, 242, 245, 0.2);
}
.questionnaires-flex-type-nps__label {
  bottom: 50px;
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: italic;
  position: absolute;
  line-height: 1.2;
  width: 100px;
}
.questionnaires-flex-type-nps__label:last-child {
  left: -8px;
  top: 50px;
}
.questionnaires-flex-type-nps--active {
  border: 0;
  font-weight: bold;
}
.questionnaires-flex-type-nps--active:after {
  content: "";
  border-radius: 50%;
  margin: -4px;
  outline: 4px solid white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.questionnaires-flex-type-nps--disabled {
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.7;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.questionnaires-flex-error__text {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  margin-top: 20px;
  padding: 0 20px;
  text-align: center;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-finished {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.questionnaires-flex-finished__image {
  display: block;
  margin: 20px 0;
  width: 150px;
}
.questionnaires-flex-finished__title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding-top: 30px;
  text-align: center;
  text-transform: uppercase;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex-intro__title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
}
.questionnaires-flex-intro__divider {
  background-color: #FFFFFF;
  border: solid 2px #FFFFFF;
  height: 0;
  margin: 20px auto;
  width: 45px;
}
.questionnaires-flex-intro__description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  word-break: break-word;
}
.questionnaires-flex-intro__description a {
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: underline;
}
.questionnaires-flex-intro__description a:hover {
  color: #D2D2D2;
  text-decoration: none;
}
.questionnaires-flex-intro__description p {
  margin: 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaires-flex {
  background-color: #636363;
  background-position: center;
  background-size: cover;
  border-radius: 0 !important;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 768px;
  max-width: 375px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.questionnaires-flex:before {
  background: url("/assets/img/waves.png");
  content: "";
  height: 310px;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;
}
.questionnaires-flex__navigation {
  padding-bottom: 55px;
  width: 100%;
}
.questionnaires-flex__navigation-icon {
  cursor: pointer;
  font-size: 15px;
  top: 20px;
}
.questionnaires-flex__navigation-icon::before {
  -webkit-text-fill-color: #FFFFFF;
  -webkit-text-stroke: #FFFFFF;
  color: #FFFFFF;
  fill: #FFFFFF;
  text-stroke: #FFFFFF;
}
.questionnaires-flex__navigation-icon-back {
  left: 20px;
  position: absolute;
}
.questionnaires-flex__navigation-icon-close {
  position: absolute;
  right: 20px;
  z-index: 2;
}
.questionnaires-flex__loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.questionnaires-flex__loading-spinner {
  position: relative;
  z-index: 5;
}
.questionnaires-flex__loading-background {
  background-color: #636363;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.questionnaires-flex__intro, .questionnaires-flex__finished, .questionnaires-flex__error {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 30px;
  overflow-y: auto;
}
.questionnaires-flex__question {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
.questionnaires-flex__question-progress {
  color: #F0F2F5;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  padding: 0 30px 10px 30px;
}
.questionnaires-flex__question-title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding: 0 30px 30px 30px;
  word-break: break-word;
}
.questionnaires-flex__question-title a {
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: underline;
}
.questionnaires-flex__question-title a:hover {
  color: #D2D2D2;
  text-decoration: none;
}
.questionnaires-flex__question-title p {
  margin: 0;
}
.questionnaires-flex__question-description {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  line-height: 14px;
  padding: 0 30px 30px 30px;
}
.questionnaires-flex__question-divider {
  background-color: #FFFFFF;
  border: solid 2px #FFFFFF;
  height: 0;
  margin: 0 30px 30px 30px;
  width: 45px;
}
.questionnaires-flex__question-options {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}
.questionnaires-flex__question-options::-webkit-scrollbar-track {
  background: none;
}
.questionnaires-flex__question-options--layout-list > * {
  border-bottom: 1px solid rgba(240, 242, 245, 0.2);
}
.questionnaires-flex__question-options--layout-list > *:first-child {
  border-top: 1px solid rgba(240, 242, 245, 0.2);
}
.questionnaires-flex__question-options--layout-grid {
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -7px;
}
.questionnaires-flex__question-options--layout-grid > * {
  padding: 7px;
  width: 50%;
}
.questionnaires-flex__question-options--type-IMAGE {
  padding: 0 30px;
}
.questionnaires-flex__question-options--type-TEXTFIELD {
  padding: 0 30px;
}
.questionnaires-flex__question-options--type-TEXTFIELD > * {
  border: 0 !important;
}
.questionnaires-flex__question-options--type-SEND_STAR {
  flex: 1 1 auto;
}
.questionnaires-flex__question-options--type-SEND_STAR > * {
  border: 0 !important;
  height: 100%;
}
.questionnaires-flex__question-options--type-NPS {
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 20px 14px;
  gap: 20px 14px;
  height: 100%;
  justify-content: center;
  margin-top: 100px;
  overflow: initial;
  padding: 0 30px;
}
.questionnaires-flex__question-options--type-NPS .questionnaires-flex__question-component {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(16.66% - 12px);
}
.questionnaires-flex__submit {
  padding: 20px 30px;
}
.questionnaires-flex__submit-button {
  align-items: center;
  background-color: #FFFFFF;
  color: #18181A;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
}
.questionnaires-flex__submit:disabled, .questionnaires-flex__submit:disabled:hover, .questionnaires-flex__submit:disabled:focus, .questionnaires-flex__submit:disabled:active {
  background: #DEE0E3;
  color: #7C8089;
  cursor: default;
}
.questionnaires-flex__submit--type-SEND_STAR {
  background-color: #FFFFFF;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}
.questionnaires-flex__submit--type-SEND_STAR > * {
  color: #FFFFFF;
}
.questionnaires-flex__anonymous {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: flex;
  margin: 0 30px 20px 30px;
  padding: 10px 20px;
}
.questionnaires-flex__anonymous-image {
  height: 60px;
  margin-right: 15px;
  max-width: 60px;
  width: 60px;
}
.questionnaires-flex__anonymous-content {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  justify-content: center;
  line-height: normal;
}
.questionnaires-flex__anonymous-NPS {
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 20px 14px;
  gap: 20px 14px;
  margin-top: 100px;
  padding: 0 30px;
}
.questionnaires-flex__anonymous-NPS .questionnaires-flex__item {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(16.66% - 12px);
}
@media only screen and (max-height: 768px) {
  .questionnaires-flex {
    max-height: 95vh;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-demographics {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.questionnaire-demographics__title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding: 40px 20px 0 20px;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  word-wrap: break-word;
}
.questionnaire-demographics__icon-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.questionnaire-demographics__info {
  padding: 0 20px;
}
.questionnaire-demographics__info-title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.questionnaire-demographics__info-subtitle {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  opacity: 0.8;
  padding-top: 5px;
  text-align: center;
}
.questionnaire-demographics__info-header-form {
  color: #FFFFFF;
  font-size: 14px;
  opacity: 0.6;
  padding: 15px 10px 10px 0;
  text-align: left;
}
.questionnaire-demographics__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 30px 0 0 0;
}
.questionnaire-demographics__form-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  height: 45px;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.questionnaire-demographics__form-field:last-child {
  border-bottom: none;
}
.questionnaire-demographics__form-content {
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  padding: 5px 20px;
}
.questionnaire-demographics__form-label {
  color: #FFFFFF;
  display: none;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 3px;
  opacity: 0.5;
  text-align: left;
  top: 20px;
}
.questionnaire-demographics__form-label--valid {
  animation-fill-mode: forwards;
  animation: zoomInAndOut 0.2s ease-in;
  display: inline;
  top: 0px;
}
.questionnaire-demographics__form-select {
  background-color: transparent !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: solid 1px #FFFFFF;
  border: none;
  color: #FFFFFF !important;
  font-family: "Roboto", sans-serif;
  font-size: 16px !important;
  opacity: 0.5;
  outline: none;
  width: 100%;
}
.questionnaire-demographics__form-select option {
  color: #000000;
  opacity: 1;
  text-indent: -2px;
}
.questionnaire-demographics__form-select option:disabled {
  color: #7C8089;
  opacity: 1;
}
.questionnaire-demographics__form-select--selected {
  opacity: 1;
}
.questionnaire-demographics__button-container {
  width: 100%;
  padding: 0 20px;
}
.questionnaire-demographics__button {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  height: 60px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0 20px;
  transition: opacity 0.5s;
  width: 100%;
}
.questionnaire-demographics__button--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.questionnaire-demographics__button--disabled:hover {
  opacity: unset;
}
@keyframes zoomInAndOut {
  from {
    top: 20px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 0.55;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-error {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.questionnaire-error__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.questionnaire-error__icon {
  margin: 20px 0;
}
.questionnaire-error__text {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 20px;
  margin: 0;
  padding: 30px 20px;
  text-align: center;
}
.questionnaire-error__button-container {
  padding: 0 30px 30px;
  width: 100%;
}
.questionnaire-error__button {
  background-color: #FFFFFF;
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  height: 40px;
  margin-bottom: 10px;
  margin: 0;
  padding: 0 20px;
  transition: opacity 0.5s;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-progressbar {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
}
.questionnaire-progressbar__question {
  background-color: #FFFFFF;
  display: inline-flex;
  flex: 1 1;
  height: 2px;
  margin: 10px 3px 0px 0px;
  opacity: 0.5;
}
.questionnaire-progressbar__question--active {
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation: changeQuestion 0.2s ease;
  background-color: #FFFFFF;
  opacity: 1;
}

@keyframes changeQuestion {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-finished {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.questionnaire-finished__title {
  color: #FFFFFF;
  display: flex;
  font-family: "Montserrat", sans-serif;
  justify-content: center;
  margin: 0;
  padding-top: 50px;
  padding: 20px;
  word-break: break-word;
  word-wrap: break-word;
}
.questionnaire-finished__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
}
.questionnaire-finished__content-title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding-top: 10px;
  text-align: left;
}
.questionnaire-finished__content-subtitle {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-top: 10px;
  text-align: center;
}
.questionnaire-finished__content-info {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding-top: 5px;
  text-align: left;
}
.questionnaire-finished__content-completed {
  line-height: 0.5;
}
.questionnaire-finished__content-image {
  display: block;
  margin: 20px auto;
  width: 100px;
}
.questionnaire-finished__button-container {
  width: 100%;
  padding: 0 20px;
}
.questionnaire-finished__button {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  height: 60px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0 20px;
  transition: opacity 0.5s;
  width: 100%;
}
@media only screen and (max-width: 415px) {
  .questionnaire-finished__content-info {
    text-align: center;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-language {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.questionnaire-language__header-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}
.questionnaire-language__header-title {
  color: #FFFFFF;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  padding: 10px 0;
  text-transform: uppercase;
  width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
  word-wrap: break-word;
}
.questionnaire-language__divider {
  border: 1px solid #FFFFFF;
  width: 250px;
}
.questionnaire-language__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  margin-bottom: 30px;
}
.questionnaire-language__content-text {
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  opacity: 0.5;
  padding: 0;
  padding: 20px 0;
  transition: 0.3s;
}
.questionnaire-language__content-text:hover {
  opacity: 1;
}
.questionnaire-language__content-text--selected {
  opacity: 1;
}
.questionnaire-language__button-container {
  width: 100%;
  padding: 0 20px;
}
.questionnaire-language__button {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  height: 60px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0 20px;
  transition: opacity 0.5s;
  width: 100%;
}
.questionnaire-language__button--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.questionnaire-language__button--disabled:hover {
  opacity: unset;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.list-items {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  text-align: center;
}
.list-items__container {
  padding: 10px 0;
  width: 100%;
}
.list-items__content--animate-content {
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation: mycontent 0.4s ease-out;
  opacity: 1;
  position: relative;
}
@keyframes mycontent {
  from {
    top: 10px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
.list-items__title {
  color: #FFFFFF;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  padding: 20px 0;
}
.list-items__options {
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
  justify-content: center;
  margin: 0;
  min-height: 65px;
  padding: 10px;
}
.list-items__options:last-child {
  border-bottom: none;
}
.list-items__options:hover:not(.list-items__options--selected) {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  border-left: 1px solid #FFFFFF;
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin: 0;
  transition: color 0.3s ease-in-out;
}
.list-items__options--selected {
  background: #FFFFFF;
  color: #000000;
  font-size: 16px;
  margin: 0;
}
.list-items__button-container {
  width: 100%;
  padding: 10px 20px;
}
.list-items__button {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  height: 60px;
  margin: 0;
  transition: opacity 0.5s;
  width: 100%;
}
.list-items__button--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.list-items__button--disabled:hover {
  opacity: unset;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.question {
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.33;
  margin: 0;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;
}
.question--animation {
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation: mymove 0.4s ease-out;
  opacity: 0;
  position: relative;
}
@keyframes mymove {
  from {
    top: 10px;
    opacity: 0;
  }
  to {
    top: 0px;
    opacity: 1;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-comments__subtitle {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
  width: 100%;
}
.questionnaire-comments__content {
  background-color: #FFFFFF;
  border-radius: 3px;
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 100%;
  padding: 20px 20px 40px 20px;
  text-align: left;
  width: 100%;
}
.questionnaire-comments__content::placeholder {
  color: #C2C7CC;
}
.questionnaire-comments__content .suite-input-area__wrapper {
  height: 100%;
  border: 0;
}
.questionnaire-comments__content .suite-input-area__input {
  padding: 0 0 20px 0;
  height: 100%;
}
.questionnaire-comments__content .suite-input-area__counter--medium {
  margin: 0;
}
.questionnaire-comments__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}
.questionnaire-comments__container-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  width: 100%;
}
.questionnaire-comments__button {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  height: 60px;
  margin: 20px 0 0 0;
  padding: 0;
  transition: opacity 0.5s;
  width: 100%;
}
.questionnaire-comments__button--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.questionnaire-comments__button-skip {
  all: unset;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  height: 60px;
  margin-bottom: 20px;
  opacity: 0.5;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
  transition: opacity 0.2s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.questionnaire-comments__button-skip:hover {
  opacity: 1;
}
@media only screen and (max-width: 415px) {
  .questionnaire-comments__subtitle {
    padding: 0 10px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.questionnaire-panel__content {
  margin-top: 20px;
  flex: 1 1;
}
.questionnaire-panel__content--comment {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.questionnaire-panel__title {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 700;
  justify-content: center;
  margin: 0 20px;
  opacity: 0.8;
  padding: 0;
  text-transform: uppercase;
  word-break: break-word;
  word-wrap: break-word;
}
.questionnaire-panel__category {
  background: #FFFFFF;
  border-radius: 5px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 20px 20px 0 20px;
  padding: 20px;
}
.questionnaire-panel__category-text {
  color: #7C8089;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-modal {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: initial;
  perspective: 0;
  transition: opacity 0.7s;
  width: 100%;
}
.questionnaire-modal--old {
  align-items: center;
  display: flex;
  height: 90vh;
  max-height: 768px;
}
.questionnaire-modal__modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.questionnaire-modal__segment {
  height: 100%;
}
.questionnaire-modal__segment:before {
  background: url(/assets/img/waves.png);
  content: "";
  height: 310px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 0;
}
.questionnaire-modal__segment--is-error {
  background-color: #636363;
}
.questionnaire-modal__segment:not(.questionnaire-modal__segment--is-loading) > * {
  height: 100%;
}
.questionnaire-modal__close {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: 2;
}
.questionnaire-modal__close-icon {
  align-items: center;
  cursor: pointer;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.questionnaire-modal__close-icon::before {
  -webkit-text-fill-color: #FFFFFF;
  -webkit-text-stroke: #FFFFFF;
  color: #FFFFFF;
  fill: #FFFFFF;
  text-stroke: #FFFFFF;
}
.questionnaire-modal--flex {
  height: 100%;
}
.questionnaire-modal__flex {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.questionnaire-modal__flex > * {
  margin: 0 auto;
}
.questionnaire-modal__container {
  border-radius: 0;
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 376px;
}
.questionnaire-modal__header {
  display: flex;
  padding: 20px;
  overflow: hidden;
  position: relative;
}
.questionnaire-modal__header-close {
  fill: #FFFFFF;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
.questionnaire-modal .questionnaire-modal {
  height: 100%;
}

.modals.dimmer .ui.scrolling.modal {
  margin: 0 !important;
}
.modals.dimmer .visible.transition {
  display: flex !important;
  flex-direction: column;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-results-question-header__title {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  margin: 0;
}
.questionnaire-results-question-header__description {
  color: #18181A;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .questionnaire-results-question-header__title {
    font-size: 16px;
  }
  .questionnaire-results-question-header__description {
    font-size: 14px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-results-option__label {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
}
.questionnaire-results-option__value {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .questionnaire-results-option.questionnaire-results-option--mobile .questionnaire-results-option__label {
    font-size: 10px;
  }
  .questionnaire-results-option.questionnaire-results-option--mobile .questionnaire-results-option__value {
    font-size: 12px;
    margin-top: 2px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-results-list {
  padding: 30px 20px;
  position: relative;
}
.questionnaire-results-list__header {
  display: flex;
}
.questionnaire-results-list__header > *:last-child {
  margin-left: auto;
}
.questionnaire-results-list__table {
  border: solid 1px #DEE0E3;
  border-radius: 5px;
}
.questionnaire-results-list__table-header > * {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  text-transform: uppercase;
}
.questionnaire-results-list__table-header, .questionnaire-results-list__table-row {
  display: flex;
}
.questionnaire-results-list__table-header > *:nth-child(1), .questionnaire-results-list__table-row > *:nth-child(1) {
  width: 15%;
}
.questionnaire-results-list__table-header > *:nth-child(2), .questionnaire-results-list__table-row > *:nth-child(2) {
  width: 30%;
}
.questionnaire-results-list__table-header > *:nth-child(3), .questionnaire-results-list__table-row > *:nth-child(3) {
  overflow-wrap: break-word;
  width: 55%;
}
.questionnaire-results-list__table-row {
  border-top: solid 1px #DEE0E3;
}
.questionnaire-results-list__table-row > * {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}
.questionnaire-results-list__table-column {
  padding: 20px;
}
.questionnaire-results-list__table-footer {
  align-items: center;
  border-top: solid 1px #DEE0E3;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
}
.questionnaire-results-list__table-loader {
  align-items: center;
  background: #FFFFFF;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 200px;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
@media (max-width: 767px) {
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-header {
    display: none;
  }
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-row {
    flex-direction: column;
    padding: 10px 0;
  }
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-row:first-child {
    border-top: 0px;
  }
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-row > *:nth-child(1) {
    width: 100%;
  }
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-row > *:nth-child(2) {
    width: 100%;
  }
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-row > *:nth-child(3) {
    width: 100%;
    font-size: 12px;
  }
  .questionnaire-results-list.questionnaire-results-list--mobile .questionnaire-results-list__table-column {
    padding: 5px 20px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-questionnaire-results {
  overflow: hidden;
  position: relative;
}
.smu-questionnaire-results__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 10px;
  width: 794px;
}
.smu-questionnaire-results__questions > * {
  border-bottom: solid 1px #DEE0E3;
}
.smu-questionnaire-results__questions > *:last-child {
  border-bottom: 0px;
}
.smu-questionnaire-results__questions:last-child {
  margin-bottom: 0;
}
.smu-questionnaire-results--mobile::before {
  display: none;
}
.smu-questionnaire-results--mobile .smu-questionnaire-results__wrapper {
  padding: 10px 0;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.survey-answer-detail {
  background-color: #F0F2F5;
  border: 1px solid #DEE0E3;
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
}
.survey-answer-detail__answer {
  width: 30%;
}
.survey-answer-detail__close {
  cursor: pointer;
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.survey-answer-detail__image {
  height: 30px;
  width: 30px;
}
.survey-answer-detail__modal .smu-generic-modal__body {
  max-height: 312px;
}
.survey-answer-detail__option, .survey-answer-detail__users {
  width: 70%;
}
.survey-answer-detail__users {
  margin-top: 10px;
}
.survey-answer-detail__users .user-avatar-list__users-hidden-ammount {
  font-size: 12px;
}
.survey-answer-detail__text {
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
}
.survey-answer-detail__title {
  color: #C2C7CC;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.surveys-answer {
  display: flex;
  flex-direction: column;
  line-height: 1.19;
  max-height: 312px;
  overflow: auto;
  padding: 30px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-results-graphics {
  padding: 30px 20px;
}
.questionnaire-results-graphics__header {
  margin-bottom: 30px;
}
.questionnaire-results-graphics__body {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
}
.questionnaire-results-graphics__detail {
  display: none;
}
.questionnaire-results-graphics__chart {
  width: 100%;
}
.questionnaire-results-graphics__chart-wrapper {
  position: relative;
  width: calc(100% - 220px);
}
.questionnaire-results-graphics__chart-x, .questionnaire-results-graphics__chart-y {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
}
.questionnaire-results-graphics__chart-x {
  overflow: hidden;
  text-align: right;
  white-space: normal;
  text-overflow: ellipsis;
  width: 220px;
}
.questionnaire-results-graphics__chart-x img {
  height: 28px;
  width: 28px;
}
.questionnaire-results-graphics__chart-y {
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.questionnaire-results-graphics__info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 200px;
}
.questionnaire-results-graphics__info-title {
  color: #C2C7CC;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 10px;
}
.questionnaire-results-graphics__info-summary > * {
  margin-bottom: 10px;
}
.questionnaire-results-graphics__info-summary > *:last-child {
  margin-bottom: 0;
}
.questionnaire-results-graphics__info .questionnaire-results-graphics__detail {
  display: flex;
}
.questionnaire-results-graphics--emoji .questionnaire-results-graphics__chart-x {
  font-size: 20px;
}
@media (max-width: 767px) {
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__detail {
    display: flex;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__body {
    flex-direction: column-reverse;
    justify-content: initial;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__chart-wrapper {
    width: 100%;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__chart-x {
    font-size: 9px;
    width: 120px;
    max-height: 55px;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__chart-y {
    font-size: 9px;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__chart .highcharts-axis-labels span .questionnaire-results-graphics__chart-y {
    opacity: 0;
    visibility: hidden;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__chart .highcharts-axis-labels span:first-child .questionnaire-results-graphics__chart-y, .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__chart .highcharts-axis-labels span:last-child .questionnaire-results-graphics__chart-y {
    opacity: 1;
    visibility: visible;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__info {
    margin-bottom: 20px;
    margin-left: 0;
    width: 100%;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__info-summary {
    display: flex;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__info-summary > * {
    width: 33.33%;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile .questionnaire-results-graphics__info .questionnaire-results-graphics__detail {
    display: none;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile.questionnaire-results-graphics--emoji .questionnaire-results-graphics__chart-x, .questionnaire-results-graphics.questionnaire-results-graphics--mobile.questionnaire-results-graphics--image .questionnaire-results-graphics__chart-x {
    width: 35px;
  }
  .questionnaire-results-graphics.questionnaire-results-graphics--mobile.questionnaire-results-graphics--emoji .questionnaire-results-graphics__chart-x {
    font-size: 20px;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.questionnaire-results-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.questionnaire-results-header__option {
  margin: 0 25px;
}
.questionnaire-results-header__option:first-child {
  margin-left: 0;
}
.questionnaire-results-header__export {
  margin: 0 0 0 auto;
  width: 140px;
}
.questionnaire-results-header__export > *:first-child {
  width: calc(100% - 38px);
}
@media (max-width: 767px) {
  .questionnaire-results-header.questionnaire-results-header--mobile .questionnaire-results-header__option {
    margin: 0 0 20px 0;
    width: 33.33%;
  }
  .questionnaire-results-header.questionnaire-results-header--mobile .questionnaire-results-header__export {
    width: 100%;
  }
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-questionnaire-results-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
  text-align: center;
}
.smu-questionnaire-results-error__title {
  color: #18181A;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.19;
  margin-top: 30px;
}
.smu-questionnaire-results-error__subtitle {
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 5px;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-questionnaire-results-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 0 40px 0;
}
.smu-questionnaire-results-layout > *:last-child {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.smu-questionnaire-results-layout__loader {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.smu-questionnaire-results-layout__loader-exporting {
  align-items: center;
  background-color: #F0F2F5;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}
.smu-questionnaire-results-layout__results {
  min-height: 100%;
}
.smu-questionnaire-results-layout--webview {
  padding: 0;
}
.smu-questionnaire-results-layout--exporting > *:last-child {
  overflow: hidden;
}
.smu-questionnaire-results-layout--error > *:last-child {
  flex: 1 1;
  min-height: auto;
}
.smu-questionnaire-results-layout--with-content {
  height: auto;
}
@media (max-width: 767px) {
  .smu-questionnaire-results-layout {
    padding-bottom: 0;
  }
  .smu-questionnaire-results-layout > *:last-child {
    padding: 0 10px;
  }
}
.share-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 90px 0 50px 0;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.smu-questionnaire-results-container {
  height: 100%;
  padding: 60px 0 40px 0;
}
.smu-questionnaire-results-container--webview {
  padding: 0;
}
.test-layout {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}
/*
// Typography Sizes
$typography-style-42:           font($font-42, 44px);
$typography-style-42-lh1:       font($font-42, 1);
$typography-style-36:           font($font-36, 38px);
$typography-style-36-lh1:       font($font-36, 1);
$typography-style-32:           font($font-32, 34px);
$typography-style-32-lh1:       font($font-32, 1);
$typography-style-30:           font($font-30, 32px);
$typography-style-30-lh1:       font($font-30, 1);
$typography-style-26:           font($font-26, 30px);
$typography-style-26-lh1:       font($font-26, 1);
$typography-style-22:           font($font-22, 26px);
$typography-style-22-lh1:       font($font-22, 1);
$typography-style-20:           font($font-20, 24px);
$typography-style-20-lh1:       font($font-20, 1);
$typography-style-18:           font($font-18, 22px);
$typography-style-18-lh1:       font($font-18, 1);
$typography-style-16:           font($font-16, 20px);
$typography-style-16-lh1:       font($font-16, 1);
$typography-style-13:           font($font-13, 18px);
$typography-style-13-lh1:       font($font-13, 1);
$typography-style-12:           font($font-12, 14px);
$typography-style-12-lh1:       font($font-12, 1);
$typography-style-11:           font($font-11, 13px);
$typography-style-11-lh1:       font($font-11, 1);


// Sup styles
$typography-style-42-sup:       font($font-20);
$typography-style-36-sup:       font($font-18);
$typography-style-32-sup:       font($font-16);
$typography-style-30-sup:       font($font-14);
$typography-style-26-sup:       font($font-12);
$typography-style-22-sup:       font($font-12);
$typography-style-20-sup:       font($font-12);
$typography-style-18-sup:       font($font-12);
$typography-style-16-sup:       font($font-11);
$typography-style-13-sup:       font($font-11);
$typography-style-12-sup:       font($font-11);
$typography-style-11-sup:       font($font-11);
*/
/**
 *  Default Theme Styles
 */
/**
  ==========================================
  [ CSS TABLE CONTENT ]
  ------------------------------------------
    1.0 BASE
      > -- 1.1 Global Settings
  ------------------------------------------
  [ END CSS TABLE CONTENT ]
  ==========================================
*/
/**
 *  1.0 BASE
 */
/**
 *  Base Styles
 */
/**
 *  Variables
 */
/**
 *  Functions
 */
.recognition__filter {
  align-items: baseline;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}
.recognition__filter-wrapper {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
}
.recognition__filter-button-wrapper {
  align-items: center;
  border-top: 1px solid #F0F2F5;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}
.recognition__filter-button {
  margin: 0px;
}
.recognition__filter-dropdown {
  background-color: #F0F2F5;
  margin-left: 17px;
  width: 170px;
}
.recognition__filter-input-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: calc(33.333% - 10px);
}
.recognition__filter-input {
  border: 1px solid #DEE0E3;
  color: #18181A;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 30px;
  outline: none;
  padding: 0px 10px;
  width: 100%;
}
.recognition__filter-input-label {
  color: #B4B4BE;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.recognition__filter-label {
  color: #7C8089;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  padding: 10px;
}
.recognition__filter-panel {
  border: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.recognition__filter-panel--active {
  max-height: 500px;
  transition: max-height 0.8s ease-in;
}
.recognition__filter-select-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: calc(33.333% - 10px);
}
.recognition__filter-select {
  background: transparent;
  background-image: linear-gradient(45deg, transparent 50%, #7C8089 50%), linear-gradient(135deg, #7C8089 50%, transparent 50%);
  background-position: calc(100% - 20px) 12px, calc(100% - 15px) 12px, calc(100% - 35px) 10px;
  background-size: 5px 5px, 5px 5px, 1px 21px;
  background-repeat: no-repeat;
  border: 1px solid #DEE0E3;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #7C8089;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 30px;
  outline: none;
  padding: 0px 35px 0px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.recognition__filter-select__option-selected {
  color: #18181A;
}
.recognition__filter-select::-ms-expand {
  display: none;
}
.recognition__filter-select-label {
  color: #B4B4BE;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.recognition__filter .suite-autocomplete__icon-wrapper {
  background-color: #F0F2F5;
}
.recognition__filter-text {
  color: #7C8089;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}
.recognition-tabs-filter {
  display: flex;
  overflow: hidden;
}
.recognition-tabs-filter__button {
  background: transparent;
  border-left: 1px solid #DEE0E3;
  border-radius: 0;
  height: 100%;
  width: 100px;
}
.recognition-tabs-filter__button:hover {
  background: #FAFAFA;
}
.recognition-tabs-filter__button--filters-applied {
  background-color: #18181A !important;
}
.recognition-tabs-filter__button--filters-applied:hover {
  background: #18181A;
}
.recognition-tabs-filter .suite-discovery__content > div {
  height: 100%;
}
.profile-timeline__header {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 1;
}
.profile-timeline__container {
  display: flex;
  justify-content: center;
  margin-top: 130px;
  padding: 20px 0 40px 0;
}
.profile-timeline__feed {
  max-width: 610px;
  width: 610px;
}
.smu-whats-new-layout {
  padding: 80px 30px 40px 30px;
}
.smu-whats-new-layout--webview {
  padding: 0;
}
