@import '~smu-global-styles/globals';

.chillout-chip {
  background-color: $os-violet;
  border-radius: 24px;
  border: solid 1px transparent;
  color: $neutral-white;
  display: inline-flex;
  font-family: $font-text;
  font-size: $font-12;
  min-height: 30px;
  padding: 8px 16px;
  text-align: left;
  user-select: none;

  &--outlined {
    background-color: transparent;
    border: solid 1px $form-border;
    color: $button-border;
  }

  &--clickable,
  &--clickable > * {
    cursor: pointer;
  }

  &--disabled {
    cursor: default;
  }
}
